import { Store, Module, ActionContext } from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Ajax from '../../../lib/ajax'
import PageResult from '@/store/entities/page-result';
import ListMutations from '../list-mutations'
import Transferencia from '@/store/entities/OZONE/transferencia'
import PageResultTransferencia from '@/store/entities/OZONE/transferencias-page-result'
import TransferenciaDash from '@/store/entities/OZONE/dashboardsTransferencias'
import ValidateImagenCampana from '@/store/entities/OZONE/validateImagenCampana';

interface ValidateImagenCampanaState extends ListState<ValidateImagenCampana> {
    editvalidateImagenesCampana: ValidateImagenCampana
   
}
class ValidateImagenMutations extends ListMutations<ValidateImagenCampana>{

}
class ValidateImagenCampanaModule extends ListModule<ValidateImagenCampanaState,any, ValidateImagenCampana>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize:10,
        list: new Array<ValidateImagenCampana>(),
        loading:false,
        editvalidateImagenesCampana: new ValidateImagenCampana()
        
    }
}
const validateImagenCampana = new ValidateImagenCampanaModule();
export default validateImagenCampana;   