declare global {
    interface RouterMeta {
        title: string;
    }
    interface Router {
        path: string;
        name?: string;
        icon?: string;
        permission?: string;
        meta?: RouterMeta;
        component: any;
        children?: Array<Router>;
    }
    interface System {
        import(request: string): Promise<any>
    }
    var System: System
}
import login from '@/views/login.vue'
import home from '../views/people/home.vue'
import main from '../views/main.vue'

export const locking = {
    path: '/locking',
    name: 'locking',
    component: () => import('../components/lockscreen/components/locking-page.vue')
};
export const loginRouter: Router = {
    path: '/',
    name: 'login',
    meta: {
        title: 'LogIn'
    },
    component: () => import('../views/login.vue')
};
export const otherRouters: Router = {
    path: '/main',
    name: 'main',
    permission: '',
    meta: { title: 'Dashboard' },
    component: main,
    icon: 'fas fa-home',
    children: [
        { path: 'home', meta: { title: 'Dashboard' }, icon: 'fas fa-home', name: 'home', component: () => import('../views/people/home.vue') },
    ]
}
export const appRouters: Array<Router> = [
    {
        path: '/buscador',
        permission: 'OZONE.Buscador',
        meta: { title: 'People Finder' },
        icon: 'fas fa-users',
        component: main,
        children: [
            { path: '', meta: { title: 'People Finder' }, name: 'buscador', component: () => import('../views/OZONE/buscador/buscador.vue') },
        ]
    },
    {
        path: '/campanas',
        permission: 'OZONE.Campanas',
        meta: { title: 'Campañas' },
        icon: 'fas fa-comments-dollar',
        component: main,
        children: [
            { path: '', meta: { title: 'Campañas' }, permission: 'OZONE.Campanas', name: 'campanas', component: () => import('../views/OZONE/campana/campana.vue') },
            { path: ':id?', meta: { title: 'Editar Campaña' }, permission: 'OZONE.Campanas.Edit',name: 'editCampana', component: () => import('../views/OZONE/campana/edit-campana.vue') },
            { path: ':id?', meta: { title: 'Crear Campaña' }, permission: 'OZONE.Campanas.Edit',name: 'createCampana', component: () => import('../views/OZONE/campana/edit-campana.vue') },
            { path: ':id?', meta: { title: 'Editar Ean' }, permission: 'OZONE.Campanas.Edit', name: 'editean', component: () => import('../views/OZONE/ean/edit-ean.vue') }
        ]
    },
    {
        path: '/participaciones',
        permission: 'OZONE.Participaciones',
        meta: { title: 'Participaciones' },
        icon: 'fas fa-user-check',
        component: main,
        children: [
            { path: '', meta: { title: 'Participaciones' },  permission: 'OZONE.Participaciones', name: 'participaciones', component: () => import('../views/OZONE/participacion/participacion.vue') },
            { path: ':id?', meta: { title: 'editParticipacion' },  permission: 'OZONE.Participaciones.Edit',name: 'editparticipacion', component: () => import('../views/OZONE/participacion/edit-participacion.vue') }
        ]
    },
    {
        path: '/gestion',
        name: 'gestion',
        permission: '',
        meta: { title: 'Gestion' },
        icon: 'fas fa-toolbox',
        component: main,
        children: [
            { path: 'exportar', icon: 'fas fa-file-download', permission: 'OZONE.Participaciones', meta: { title: 'Exportaciones' }, name: 'exportar', component: () => import('../views/OZONE/exportaciones/export.vue') },
            { path: 'importacionestotal', icon: 'fas fa-file-upload', permission: 'OZONE.Participaciones', meta: { title: 'Importaciones' }, name: 'importacionestotal', component: () => import('../views/OZONE/importacionestotal/importaciontotal.vue') },
            { path: 'deduplicar', icon: 'fas fa-baby', permission: 'OZONE.Participaciones', meta: { title: 'Club Vip Dodot' }, name: 'deduplicar', component: () => import('../views/OZONE/deduplicacion/deduplica.vue') },
            { path: 'sorteo', icon: 'fas fa-hat-wizard', permission: 'OZONE.Participaciones', meta: { title: 'Sorteos' }, name: 'sorteo', component: () => import('../views/OZONE/sorteo/sorteo.vue') }
        ]
    },
    {
        path: '/transferencias',
        name: 'transferencias',
        permission: '',
        meta: { title: 'Transferencias' },
        icon: 'fas fa-exchange-alt',
        component: main,
        children: [
            { path: 'list', icon: 'fas fa-euro-sign', permission: 'OZONE.Transferencias', meta: { title: 'Dashboard' }, name: 'transferlist', component: () => import('../views/OZONE/transferencia/transferencia.vue') },
            { path: 'exportaciones', icon: 'fas fa-file-invoice-dollar', permission: 'OZONE.Transferencias', meta: { title: 'Exportaciones SEPA' }, name: 'exportaciones', component: () => import('../views/OZONE/transferencia/exportacion.vue') },
            { path: 'importaciones', icon: 'fas fa-file-upload', permission: 'OZONE.Transferencias', meta: { title: 'Importacion Transferencias' }, name: 'importaciones', component: () => import('../views/OZONE/transferencia/import-transferencia.vue') },
        ]
    },
    {
        path: '/envios',
        name: 'envios',
        permission: '',
        meta: { title: 'Envíos' },
        icon: 'fa fa-shipping-fast',
        component: main,
        children: [
            { path: 'list', icon: 'fas fa-truck', permission: 'OZONE.Envios', meta: { title: 'Dashboard' }, name: 'envioslist', component: () => import('../views/OZONE/envios/envios.vue') },
            { path: 'devoluciones', icon: 'fas fa-mail-bulk', permission: 'OZONE.Envios.Return', meta: { title: 'Devoluciones' }, name: 'devoluciones', component: () => import('../views/OZONE/envios/devoluciones.vue') },
            { path: 'importacion', icon: 'fas fa-file-upload', permission: 'OZONE.Envios.Import', meta: { title: 'Importacion Envios' }, name: 'importacion', component: () => import('../views/OZONE/envios/import-envios.vue') },
            { path: 'ficherosEnvios', icon: 'fas fa-file-download', permission: 'OZONE.Envios.Import', meta: { title: 'Ficheros de Envios' }, name: 'ficherosdeEnvios', component: () => import('../views/OZONE/envios/ficherosEnvio.vue') },
            //{ path: 'ficherosImpresion', icon: 'fas fa-solid fa-copy', permission: 'OZONE.Envios.Import', meta: { title: 'Ficheros de Impresión' }, name: 'ficherosImpresion', component: () => import('../views/OZONE/envios/ficherosImpresion.vue') },
        ]
    },
    {
        path: '/coms',
        name: 'coms',
        permission: '',
        meta: { title: 'Comunicaciones' },
        icon: 'fa fa-envelope',
        component: main,
        children: [
            { path: 'masivo', icon: 'fas fa-bullhorn', permission: 'OZONE.Envios', meta: { title: 'Masivos' }, name: 'envioslist', component: () => import('../views/OZONE/comunicaciones/masivos.vue') },
            { path: 'importacion-masivos', icon: 'fas fa-file-upload', permission: 'OZONE.Envios.Import', meta: { title: 'Importacion Masivos' }, name: 'importacion', component: () => import('../views/OZONE/comunicaciones/import-masivos.vue') },
        ]
    },
    {
        path: '/reporting',
        name: 'reporting',
        permission: '',
        meta: { title: 'Reportes' },
        icon: 'fa fa-chart-bar',
        component: main,
        children: [
            { path: 'transferencias', icon: 'fas fa-exchange-alt', permission: 'OZONE.Transferencias', meta: { title: 'Transferencias' }, name: 'transferdash', component: () => import('../views/OZONE/transferencia/dashboard.vue') },
            { path: 'campanas', icon: 'fas fa-comments-dollar', permission: 'OZONE.Transferencias', meta: { title: 'Campañas' }, name: 'transferdashcampana', component: () => import('../views/OZONE/campana/dashboard.vue') },
            //{ path: 'participaciones', icon: 'fas fa-user-check', permission: 'OZONE.Transferencias', meta: { title: 'Participaciones' }, name: 'transferdash', component: () => import('../views/OZONE/participacion/dashboard.vue') },
        ]
    },
    {
        path: '/grabacion',
        name: 'grabacion',
        permission: '',
        meta: { title: 'Grabacion' },
        icon: 'fa fa-keyboard',
        component: main,
        children: [
            //{ path: 'cupones', icon: 'fas fa-barcode', permission: 'OZONE.Participaciones', meta: { title: 'Cupones' }, name: 'listcoupons', component: () => import('../views/OZONE/grabacion/list-cupones.vue') },
            { path: '', icon: 'fas fa-address-card', permission: 'OZONE.Participaciones', meta: { title: 'Campañas de grabación' }, name: 'forms', component: () => import('../views/OZONE/grabacion/list-formularios.vue')},
            { path: ':id?', meta: { title: 'Grabación' } , permission: 'OZONE.Participaciones', name: 'editforms', component: () => import('../views/OZONE/grabacion/formularios.vue') }
            
        ]
    },
    {
        path: '/logistica',
        name: 'logistica',
        permission: '',
        meta: { title: 'Logistica' },
        icon: 'fa fa-dolly-flatbed',
        component: main,
        children: [
            { path: 'charlie-plantillas', icon: "fas fa-inbox", permission: 'OZONE.Participaciones', meta: { title: 'Importatcion Plantillas' }, name: 'charlie-plantillas', component: () => import('../views/OZONE/logistica/charlie-plantillas.vue') },                        
            { path: 'charlie-mapeo', icon: "fas fa-inbox", permission: 'OZONE.Participaciones', meta: { title: 'Importatcion Mapeo' }, name: 'charlie-mapeo', component: () => import('../views/OZONE/logistica/charlie-mapeo.vue') },                        
            { path: 'fulfillment', icon: 'fas fa-boxes', permission: 'OZONE.Participaciones', meta: { title: 'Fulfillment' }, name: 'fulfillment', component: () => import('../views/OZONE/logistica/fulfillment.vue') },
            {   path: '/',
                name: 'franqueo',
                permission: 'OZONE.Mantenimiento',
                meta: { title: 'Franqueo' },
                icon: 'fas fa-mail-bulk',
                //component: () => import('../views/OZONE/logistica/franqueo-historico.vue'),
                component: {
                    render(f){
                        return f('router-view')
                    }
                },
                children: [
                    { 
                        path: 'franqueo/historico',
                        name: 'historico',
                        permission: 'OZONE.Mantenimiento',
                        meta: { title: 'Histórico' },
                        icon: 'fas fa-history',
                        component: () => import('../views/OZONE/logistica/franqueo-historico.vue')
                    },
                    { 
                        path: 'franqueo/registro',
                        name: 'registro',
                        permission: 'OZONE.Mantenimiento',
                        meta: { title: 'Registrar' },
                        icon: 'far fa-save',
                        component: () => import('../views/OZONE/logistica/franqueo-registrar.vue')
                    },
                    { 
                        path: 'franqueo/maquinas',
                        name: 'maquinas',
                        permission: 'OZONE.Mantenimiento',
                        meta: { title: 'Máquinas' },
                        icon: 'fas fa-print',
                        component: () => import('../views/OZONE/logistica/franqueo-maquinas.vue')
                    },
                    { 
                        path: 'franqueo/informes',
                        name: 'informes',
                        permission: 'OZONE.Mantenimiento',
                        meta: { title: 'Informes' },
                        icon: 'far fa-file-alt',
                        component: () => import('../views/OZONE/logistica/franqueo-informes.vue')
                    }
                ]
            }
            //{ path: 'franqueo', icon: 'fas fa-mail-bulk', permission: 'OZONE.Participaciones', meta: { title: 'Franqueo' }, name: 'franqueo', component: () => import('../views/OZONE/logistica/franqueo.vue') },
        ]
    },
    {
        path: '/setting',
        name: 'setting',
        permission: 'OZONE.Mantenimiento',
        meta: { title: 'Mantenimiento' },
        icon: 'fas fa-cogs',
        component: main,
        children: [
            {
                path: '/',
                name: 'campañas',
                permission: 'OZONE.Mantenimiento',
                meta: { title: 'Campañas' },
                icon: 'fa fa-funnel-dollar',
                component: {
                    render(c) {
                        return c('router-view')
                    }
                },
                children: [
                    {
                        path: 'grupo', icon: 'fas fa-object-group', permission: 'OZONE.Mantenimiento', meta: { title: 'Grupos' }, component: {
                            render(c) {
                                return c('router-view')
                            }
                        }, children: [
                            {
                                path: '', meta: { title: 'Grupos' }, name: 'grupo', component: () => import('../views/OZONE/grupo/grupo.vue')

                            },
                            {
                                path: ':id?', meta: { title: 'editGrupos' }, name: 'editgrupo', component: () => import('../views/OZONE/grupo/edit-grupo.vue')

                            }]
                    },
                    //{ path: 'settingsCampana', permission: 'OZONE.Campanas', meta: { title: 'Campañas' }, name: 'settingsCampanas', component: () => import('../views/OZONE/campana/campana.vue') },
                    {
                        path: 'campo', icon: 'fas fa-stream', permission: 'OZONE.Mantenimiento', meta: { title: 'Campos' }, component: {
                            render(c) {
                                return c('router-view')
                            }
                        }, children: [
                            {
                                path: '', meta: { title: 'Campos' }, name: 'campo', component: () => import('../views/OZONE/campo/campo.vue')

                            },
                            {
                                path: ':id?', meta: { title: 'editCampos' }, name: 'editcampo', component: () => import('../views/OZONE/campo/edit-campo.vue')

                            }]
                    },
                    { path: 'email', icon: 'fas fa-at', permission: 'OZONE.Mantenimiento', meta: { title: 'Emails' }, name: 'email', component: () => import('../views/OZONE/email/email.vue') },

                    { path: 'cuentaEmisora', icon: 'fas fa-landmark', permission: 'OZONE.Mantenimiento', meta: { title: 'CuentaEmisora' }, name: 'cuentaEmisora', component: () => import('../views/OZONE/cuentaEmisora/cuentaEmisora.vue') },
                    { path: 'callCenter', icon: 'fas fa-headset', permission: 'OZONE.Mantenimiento', meta: { title: 'Call Center' }, name: 'callCenter', component: () => import('../views/OZONE/callCenter/callCenter.vue') },
                    { path: 'tiendas', icon: 'fas fa-store-alt', permission: 'OZONE.Mantenimiento', meta: { title: 'Tiendas' }, name: 'tiendas', component: () => import('../views/OZONE/tiendas/tiendas.vue') },
                ]
            },
            {
                path: '/',
                name: 'partic',
                permission: 'OZONE.Mantenimiento',
                meta: { title: 'Participaciones' },
                icon: 'fa fa-users-cog',
                component: {
                    render(c) {
                        return c('router-view')
                    }
                },
                children: [
                    { path: 'estadoParticipacion', icon: 'fas fa-heartbeat', permission: 'OZONE.Mantenimiento', meta: { title: 'EstadoParticipacion' }, name: 'estadoParticipacion', component: () => import('../views/OZONE/estadoParticipacion/estadoParticipacion.vue') },
                    { path: 'subEstadoParticipacion', icon: 'fas fa-heartbeat', permission: 'OZONE.Mantenimiento', meta: { title: 'SubEstadoParticipacion' }, name: 'subEstadoParticipacion', component: () => import('../views/OZONE/subEstadoParticipacion/subEstadoParticipacion.vue') },

                ]
            },
            {
                path: '/',
                name: 'env',
                permission: 'OZONE.Mantenimiento',
                meta: { title: 'Envios' },
                icon: 'fa fa-truck',
                component: {
                    render(c) {
                        return c('router-view')
                    }
                },
                children: [
                    { path: 'metodoEnvio', icon: 'fas fa-shapes', permission: 'OZONE.Mantenimiento', meta: { title: 'Metodo de Envío' }, name: 'metodoEnvio', component: () => import('../views/OZONE/metodoEnvio/metodoEnvio.vue') },
                    { path: 'motivoDevolucion', icon: 'fas fa-bomb', permission: 'OZONE.Mantenimiento', meta: { title: 'Motivo de Devolucion' }, name: 'motivodevolucion', component: () => import('../views/OZONE/motivoDevolucion/motivoDevolucion.vue') },
                    { path: 'disenos', icon: 'fas fa-bomb', permission: 'OZONE.Mantenimiento', meta: { title: 'Disenos Exportacion' }, name: 'disenos', component: () => import('../views/OZONE/disenos.vue') },
                ]
            },
            {
                path: '/',
                name: 'seguridad',
                permission: 'OZONE.Mantenimiento',
                meta: { title: 'Seguridad' },
                icon: 'fa fa-shield-alt',
                component: {
                    render(c) {
                        return c('router-view')
                    }
                },
                children: [
                    { path: 'user', icon: 'fas fa-users', permission: 'OZONE.Mantenimiento', meta: { title: 'Usuarios' }, name: 'user', component: () => import('../views/setting/user/user.vue') },
                    { path: 'role', icon: 'fas fa-user-tag', permission: 'OZONE.Mantenimiento', meta: { title: 'Roles' }, name: 'role', component: () => import('../views/setting/role/role.vue') },
                    { path: 'tenant', icon: 'fas fa-globe', permission: 'OZONE.Mantenimiento', meta: { title: 'Tenants' }, name: 'tenant', component: () => import('../views/setting/tenant/tenant.vue') },

                ]
            },
            {
                path: '/',
                name: 'people',
                permission: 'OZONE.Mantenimiento',
                meta: { title: 'People' },
                icon: 'fa fa-building',
                component: {
                    render(c) {
                        return c('router-view')
                    }
                },
                children: [
                    {
                        path: 'cliente', icon: 'fas fa-user-tie', permission: 'OZONE.Mantenimiento', meta: { title: 'Clientes' }, component: {
                            render(c) {
                                return c('router-view')
                            }
                        }, children: [

                            {
                                path: '', meta: { title: 'Clientes' }, name: 'clientes', component: () => import('../views/OZONE/cliente/cliente.vue')

                            },
                            {
                                path: ':id?', meta: { title: 'editClientes' }, name: 'editcliente', component: () => import('../views/OZONE/cliente/edit-cliente.vue')

                            }]
                    },
                    { path: 'marca', icon: 'fas fa-trademark', permission: 'OZONE.Mantenimiento', meta: { title: 'Marcas' }, name: 'marca', component: () => import('../views/OZONE/marca/marca.vue') },

                ]
            },
        ]
    },
]
export const routers = [
    loginRouter,
    locking,
    ...appRouters,
    otherRouters
];
