import BaseInput from '@/components/Inputs/BaseInput.vue';
import BaseDatePicker from '@/components//Inputs/BaseDatePicker.vue';
import BaseFiltrableDropdown from '@/components/Inputs/BaseFiltrableDropdown.vue';
import BaseDropdown from '@/components/BaseDropdown.vue';
import Modal from '@/components/Modal.vue';
import BaseButton from '@/components/BaseButton.vue';
import BaseCheckbox from '@/components/Inputs/BaseCheckbox.vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import Collapse from '@/components/Collapse/Collapse.vue'
import DefaultLayout from '@/layouts/Default.vue'
import DashboardLayout from '@/layouts/Dashboard.vue'

const GlobalComponents = {
  install(Vue) {
    Vue.component("layout-default", DefaultLayout);
    Vue.component("layout-dashboard", DashboardLayout);
    Vue.component(BaseButton.name, BaseButton);
    Vue.component(BaseCheckbox.name, BaseCheckbox);
    Vue.component(BaseInput.name, BaseInput);
    Vue.component(BaseDatePicker.name, BaseDatePicker);
    Vue.component(BaseFiltrableDropdown.name, BaseFiltrableDropdown);
    Vue.component(BaseDropdown.name, BaseDropdown);
    Vue.component(Modal.name, Modal);
    Vue.component('validation-provider', ValidationProvider)
    Vue.component('validation-observer', ValidationObserver)
    Vue.component(Collapse.name, Collapse);
  }
};

export default GlobalComponents;
