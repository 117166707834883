import { Store, Module, ActionContext } from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Ajax from '../../../lib/ajax'
import PageResult from '@/store/entities/page-result';
import ListMutations from '../list-mutations'
import Envio from '@/store/entities/OZONE/envio'
import PageResultTransferencia from '@/store/entities/OZONE/transferencias-page-result'
import TransferenciaDash from '@/store/entities/OZONE/dashboardsTransferencias'
import EnvioGroup from '@/store/entities/OZONE/envioGroup';
import FinderResult from '@/store/entities/OZONE/finderResult';

interface FinderState extends ListState<FinderResult> {
    dashboard: TransferenciaDash,
    totalCountCampos: number,
    totalImporte: number,
    currentPageCampos: number,
    pageSizeCampos: number,
    totalCountGroup: number,
    currentPageGroup: number,
    download: string,
    loadingGroup: boolean,
}

class FinderModule extends ListModule<FinderState, any, FinderResult>{
    state = {
        totalCount: 0,
        totalCountGroup: 0,
        currentPage: 1,
        currentPageGroup: 1,
        pageSize: 10,
        list: new Array<FinderResult>(),
        loading: false,
        dashboard: new TransferenciaDash(),
        pageSizeCampos: 10,
        download: null,
        totalImporte: 0,
    }
    actions = {
        async getAll(context: ActionContext<FinderState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.post('/api/services/app/Finder/FindAll', payload.data);
            context.state.loading = false;
            let page = reponse.data.result as PageResult<FinderResult>;
            context.state.totalCount = page.totalCount;
            context.state.list = page.items;
        },
    };
    mutations = {
        setCurrentPage(state: FinderState, page: number) {
            state.currentPage = page;
        },
        setPageSize(state: FinderState, pagesize: number) {
            
            state.pageSize = pagesize;
        },
        clearData(state: FinderState) {
            state.list = [];
        },
        setCurrentPageGroup(state: FinderState, page: number) {
            state.currentPageGroup = page;
        },
    }
}
const finder = new FinderModule();
export default finder;