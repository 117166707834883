import {Store,Module,ActionContext} from 'vuex'
import Ajax from '../../../lib/ajax'
import ListMutations from '../list-mutations'
import TipoFormaPagoCashback from './../../entities/OZONE/tipoFormaPagoCashback';

interface TipoFormaPagoCashbackState  {
    list: Array<TipoFormaPagoCashback>,
    loading:boolean
}
class TipoFormaPagoCashbackMutations extends ListMutations<TipoFormaPagoCashback>{

}
class TipoFormaPagoCashbackModule implements Module<TipoFormaPagoCashbackState, TipoFormaPagoCashback>{
    namespaced=true;
    state = {
        list: new Array< TipoFormaPagoCashback>(),
        loading:false,
    }
    actions={
        async getAll(context:ActionContext<TipoFormaPagoCashbackState,any>,payload:any){
            context.state.loading=true;
            let response=await Ajax.get('/api/services/app/Campana/GetAllTiposPagoCashback');
            context.state.loading=false;
            context.state.list=response.data.result;
        }
    };
}
const tipoFormaPagoCashbackModule=new TipoFormaPagoCashbackModule();
export default tipoFormaPagoCashbackModule;