var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-layout-sider',{staticClass:"sider-primary",class:[
    'ant-layout-sider-' + _vm.sidebarColor,
    'ant-layout-sider-' + _vm.sidebarTheme,
  ],style:({ backgroundColor: 'transparent' }),attrs:{"collapsible":"","breakpoint":"lg","collapsed-width":"0","width":"250px","collapsed":_vm.sidebarCollapsed,"trigger":null,"theme":"light"},on:{"collapse":function($event){return _vm.$emit('toggleSidebar', !_vm.sidebarCollapsed)}}},[_c('div',{staticClass:"brand"},[_c('img',{staticStyle:{"max-height":"5vh"},attrs:{"id":"people-logo-sidebar","src":_vm.tenantImg}}),_c('span',{staticStyle:{"margin-left":"3vh"}},[_vm._v("OZONE")])]),_c('hr'),_vm._l((_vm.menuList),function(item){return _c('a-menu',{key:item.name,attrs:{"theme":"light","mode":"inline"}},[(item.children.length == 1 || item.children[0].path === '')?_vm._t("default",function(){return [_c('a-menu-item',[_c('router-link',{attrs:{"to":item.path + '/' + item.children[0].path,"href":'/#' + item.path + '/' + item.children[0].path}},[_c('span',{staticClass:"icon"},[_c('i',{class:item.icon})]),_c('span',{staticClass:"label"},[_vm._v(_vm._s(item.meta.title))])])],1)]}):_vm._e(),(item.children.length > 1 && item.children[0].path !== '')?_vm._t("default",function(){return [_c('a-sub-menu',[_c('template',{slot:"title"},[_c('span',{staticClass:"icon"},[_c('i',{class:item.icon ? item.icon : 'fas fa-times'})]),_c('span',{staticClass:"label"},[_vm._v(_vm._s(item.meta.title))])]),_vm._l((item.children.filter(
            (i) =>
              i.children == null ||
              (i.children != null &&
                (i.children.length == 1 || i.children[0].path == ''))
          )),function(sitem){return _c('a-menu-item',{key:sitem.name,staticStyle:{"margin-left":"10px"}},[_c('router-link',{attrs:{"to":item.path + '/' + sitem.path,"href":'/#' + item.path + '/' + sitem.path}},[_c('span',{staticClass:"icon"},[_c('i',{class:sitem.icon ? sitem.icon : 'fas fa-times'})]),_c('span',{staticClass:"label"},[_vm._v(_vm._s(sitem.meta.title))])])],1)}),_vm._l((item.children.filter((i) => i.children != null)),function(ssitem){return _c('a-sub-menu',{key:ssitem.name,staticStyle:{"margin-left":"10px"}},[_c('template',{slot:"title"},[_c('span',{staticClass:"icon"},[_c('i',{class:ssitem.icon ? ssitem.icon : 'fas fa-times'})]),_c('span',{staticClass:"label"},[_vm._v(_vm._s(ssitem.meta.title))])]),_vm._l((ssitem.children),function(sitem){return _c('a-menu-item',{key:sitem.name,staticStyle:{"margin-left":"10px"}},[_c('router-link',{attrs:{"to":item.path + ssitem.path + sitem.path,"href":'/#' + item.path + ssitem.path + sitem.path}},[_c('span',{staticClass:"icon"},[_c('i',{class:sitem.icon ? sitem.icon : 'fas fa-times'})]),_c('span',{staticClass:"label"},[_vm._v(_vm._s(sitem.meta.title))])])],1)})],2)})],2)]}):_vm._e()],2)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }