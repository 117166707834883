import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Ajax from '../../../lib/ajax'
import PageResult from '@/store/entities/page-result';
import TerminalBizum from '@/store/entities/OZONE/terminalBizum'



interface TerminalBizumState  extends ListState<TerminalBizum>{
    editTerminalBizum : TerminalBizum
        claveFirma: string;
        versionFirma: string;
}
class TerminalBizumModule extends ListModule<TerminalBizumState,any, TerminalBizum>{
    state={
        totalCount :0,
        currentPage:1,
        pageSize:10,
        list: new Array<TerminalBizum>(),
        loading:false,
        editCuentaEmisora :new TerminalBizum(),
      
    }
    actions={
        async getAll(context:ActionContext<TerminalBizumState,any>,payload:any){
            context.state.loading = true;
            let reponse=await Ajax.get('/api/services/app/TerminalesBizum/GetAll',{params:payload.data});
            context.state.loading=false;
            let page=reponse.data.result as PageResult<TerminalBizum>;
            context.state.totalCount=page.totalCount;
            context.state.list=page.items;
            
        },
        async create(context: ActionContext<TerminalBizumState, any>, payload: any) {
            
            await Ajax.post('/api/services/app/TerminalesBizum/CreateOrEdit',payload.data);
            

        },
        async update(context:ActionContext<TerminalBizumState,any>,payload:any){
            await Ajax.post('/api/services/app/TerminalesBizum/CreateOrEdit',payload.data);
        },
        async delete(context:ActionContext<TerminalBizumState,any>,payload:any){
            await Ajax.delete('/api/services/app/TerminalesBizum/Delete?Id='+payload.data.id);
            
        }
    }
    mutations={
        setCurrentPage(state:TerminalBizumState,page:number){
            state.currentPage=page;
        },
        
        setPageSize(state:TerminalBizumState,pagesize:number){
            
            state.pageSize=pagesize;
        },
        edit(state:TerminalBizumState,terminalBizum:TerminalBizum){
            
            state.editTerminalBizum=terminalBizum;
           
        },
        
    }
    
}
const terminalBizumModule=new TerminalBizumModule();
export default terminalBizumModule;