import Entity from '../entity'
import { EstadoParticipacion } from './estadoParticipacion';
import SubEstadoParticipacion from './subEstadoParticipacion';
export default class CallCenterEstadoParticipacion extends Entity<number>{
   
    vozitelCampanaId: number;
    estadoParticipacion: EstadoParticipacion;
    estadoParticipacionId: number;
    subEstadoParticipacion: SubEstadoParticipacion;
    subEstadoParticipacionId: number;
  
}