import Entity from '../entity'
import Campo from './campo';
import ReglasTicket from './reglasTicket';
export default class ReglasTicketDato extends Entity<number>{
    reglasTicketId: number;
    reglasTicketNombre: string
    reglasTicketDescripcion: string
    campoId: number;
    campoNombre: string;
    campoDescripcion: string;
    campanaId: number;
    regla: string;
    reglaTicketType: number;
    
}