import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Ajax from '../../../lib/ajax'
import PageResult from '@/store/entities/page-result';
import ListMutations from '../list-mutations'
import CampanaTipo from './../../entities/OZONE/campanaTipo';

interface CampanaTipoState  {
    editCampanaTipo: CampanaTipo,
    list: Array<CampanaTipo>,
    loading:boolean
}
class CampanaTipoMutations extends ListMutations<CampanaTipo>{

}
class CampanaTipoModule implements Module<CampanaTipoState, CampanaTipo>{
    namespaced=true;
    state = {
        list: new Array< CampanaTipo>(),
        loading:false,
        editCampanaTipo:new  CampanaTipo(),
    }
    actions={
        async getAll(context:ActionContext<CampanaTipoState,any>,payload:any){
            context.state.loading=true;
            let response=await Ajax.get('/api/services/app/Campana/GetAllCampanaType');
            context.state.loading=false;
            context.state.list=response.data.result;
        }
       
        // async get(context:ActionContext<CampanaTipoState,any>,payload:any){
        //     let reponse=await Ajax.get('/api/services/app/CampanaTipo/GetCampanaTipoForEdit?Id='+payload.id);
        //     return reponse.data.result as CampanaTipo;
        // },
    };
}
const campanaTipoModule=new CampanaTipoModule();
export default campanaTipoModule;