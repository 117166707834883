import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Ajax from '../../../lib/ajax'
import PageResult from '@/store/entities/page-result';
import ListMutations from '../list-mutations'
import { EstadoParticipacion, EstadoParticipacionTree } from '@/store/entities/OZONE/estadoParticipacion'

interface EstadoParticipacionState extends ListState<EstadoParticipacion>{
    editEstadoParticipacion: EstadoParticipacion,
    estadoParticipacionTree:Array<EstadoParticipacionTree>
}
class EstadoParticipacionMutations extends ListMutations<EstadoParticipacion>{

}
class EstadoParticipacionModule extends ListModule<EstadoParticipacionState,any, EstadoParticipacion>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize:10,
        list: new Array< EstadoParticipacion>(),
        loading:false,
        editEstadoParticipacion: new EstadoParticipacion(),
        estadoParticipacionTree: new Array<EstadoParticipacionTree>()
    }
    actions={
        async getAll(context:ActionContext<EstadoParticipacionState,any>,payload:any){
            context.state.loading = true;
            let reponse = await Ajax.post('/api/services/app/EstadoParticipacion/FindAll', payload.data );
            context.state.loading=false;
            let page=reponse.data.result as PageResult<EstadoParticipacion>;
            context.state.totalCount=page.totalCount;
            context.state.list=page.items;
        },
        async getAllByCampana(context:ActionContext<EstadoParticipacionState,any>,payload:any){
            context.state.loading = true;
            let reponse = await Ajax.post('/api/services/app/EstadoParticipacion/FindAllByCampana', payload.data );
            context.state.loading=false;
            let page=reponse.data.result as Array<EstadoParticipacion>;
            context.state.list=page;
            
        },
        async getAllForTree(context:ActionContext<EstadoParticipacionState,any>,payload:any){
            context.state.loading = true;
            let reponse = await Ajax.get('/api/services/app/EstadoParticipacion/GetAllForTree',{params:payload.data} );
            context.state.loading=false;
            let page=reponse.data.result as PageResult<EstadoParticipacionTree>;
            context.state.totalCount=page.totalCount;
            context.state.estadoParticipacionTree=page.items;
        },
        async create(context: ActionContext<EstadoParticipacionState, any>, payload: any) {
            await Ajax.post('/api/services/app/EstadoParticipacion/CreateOrEdit',payload.data);
        },
        async update(context:ActionContext<EstadoParticipacionState,any>,payload:any){
            await Ajax.post('/api/services/app/EstadoParticipacion/CreateOrEdit',payload.data);
        },
        async delete(context:ActionContext<EstadoParticipacionState,any>,payload:any){
            await Ajax.delete('/api/services/app/EstadoParticipacion/Delete?Id='+payload.data.id);
        },
        async get(context:ActionContext<EstadoParticipacionState,any>,payload:any){
            let reponse=await Ajax.get('/api/services/app/EstadoParticipacion/GetEstadoParticipacionForEdit?Id='+payload.id);
            return reponse.data.result as EstadoParticipacion;
        },
    };
    mutations={
        setCurrentPage(state:EstadoParticipacionState,page:number){
            state.currentPage=page;
        },
        setPageSize(state:EstadoParticipacionState,pagesize:number){
            state.pageSize=pagesize;
        },
        edit(state:EstadoParticipacionState,estadoParticipacion:EstadoParticipacion){
            state.editEstadoParticipacion=estadoParticipacion;
        }
    }
}
const estadoParticipacionModule=new EstadoParticipacionModule();
export default estadoParticipacionModule;