import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Ajax from '../../../lib/ajax'
import PageResult from '@/store/entities/page-result';
import ListMutations from '../list-mutations'
import MotivoDevolucion from '@/store/entities/OZONE/motivoDevolucion'
interface MotivoDevolucionState extends ListState<MotivoDevolucion>{
    editMotivoDevolucion : MotivoDevolucion
    
}
class MotivoDevolucionMutations extends ListMutations<MotivoDevolucion>{
    
}
class MotivoDevolucionModule extends ListModule<MotivoDevolucionState,any, MotivoDevolucion>{
    state={
       totalCount :0,
        currentPage:1,
        pageSize:10,
        list: new Array<MotivoDevolucion>(),
        loading:false,
        editMotivoDevolucion :new MotivoDevolucion(),

        
    }
    actions={
        async getAll(context:ActionContext<MotivoDevolucionState,any>,payload:any){
            
            context.state.loading = true;
            let reponse=await Ajax.get('/api/services/app/MotivoDevolucion/GetAll',{params:payload.data});
            context.state.loading=false;
            let page=reponse.data.result as PageResult<MotivoDevolucion>;
            context.state.totalCount=page.totalCount;
            context.state.list=page.items;
        },
          
        async create(context: ActionContext<MotivoDevolucionState, any>, payload: any) {
            
            await Ajax.post('/api/services/app/MotivoDevolucion/CreateOrEdit',payload.data);
            
        },
        async update(context:ActionContext<MotivoDevolucionState,any>,payload:any){
            await Ajax.post('/api/services/app/MotivoDevolucion/CreateOrEdit',payload.data);
        },
        async delete(context:ActionContext<MotivoDevolucionState,any>,payload:any){
            await Ajax.delete('/api/services/app/MotivoDevolucion/Delete?Id='+payload.data.id);
        },
        async get(context: ActionContext<MotivoDevolucionState, any>, payload: any) {
            let response = await Ajax.get('/api/services/app/MotivoDevolucion/GetGrupoForEdit?Id=' + payload.id);
            //context.state.editGrupo = response.data.result.grupo
            return response.data.result.grupo as MotivoDevolucion;
        },
    };
    mutations={
        setCurrentPage(state:MotivoDevolucionState,page:number){
            state.currentPage=page;
        },     
        setPageSize(state:MotivoDevolucionState,pagesize:number){
            state.pageSize=pagesize;
        },
        edit(state:MotivoDevolucionState,motivoDevolucion:MotivoDevolucion){
            
            state.editMotivoDevolucion=motivoDevolucion;          
        },
        
    }
    
}
const motivoDevolucionModule=new MotivoDevolucionModule();
export default motivoDevolucionModule;