import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Ajax from '../../../lib/ajax'
import PageResult from '@/store/entities/page-result';
import ListMutations from '../list-mutations'
import Campo from '@/store/entities/OZONE/campo'
import PropertyType from '@/store/entities/OZONE/propertyType';
import Rules from '@/store/entities/OZONE/rules';
import Result from '@/store/entities/result';

interface CampoState extends ListState<Campo>{
    editCampo: Campo,
    listPropertyType: Array<PropertyType>
    listRules: Array<Rules>
}
class EstadoCampoMutations extends ListMutations<Campo>{

}
class CampoModule extends ListModule<CampoState,any, Campo>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize:10,
        list: new Array<Campo>(),
        loading:false,
        editCampana: new Campo(),
        listPropertyType: new Array<PropertyType>(),
        listRules: new Array<Rules>()
    }
    actions={
        async getAll(context:ActionContext<CampoState,any>,payload:any){
            context.state.loading=true;
            let reponse=await Ajax.get('/api/services/app/Campo/GetAll',{params:payload.data});
            context.state.loading=false;
            let page=reponse.data.result as PageResult<Campo>;
            context.state.totalCount=page.totalCount;
            context.state.list=page.items;
        },
        async getAllCamposNull(context:ActionContext<CampoState,any>,payload:any){
            
            context.state.loading=true;
            let reponse=await Ajax.get('/api/services/app/Campo/GetAllCamposNull',{params:payload.data});
            context.state.loading=false;
            let page = reponse.data.result as PageResult<Campo>;
            context.state.totalCount = page.totalCount;
            context.state.list=page.items;
        },
      
        async create(context: ActionContext<CampoState, any>, payload: any) {
            await Ajax.post('/api/services/app/Campo/CreateOrEdit',payload.data);
        },
        async update(context:ActionContext<CampoState,any>,payload:any){
            await Ajax.post('/api/services/app/Campo/CreateOrEdit',payload.data);
        },
        async delete(context:ActionContext<CampoState,any>,payload:any){
            await Ajax.delete('/api/services/app/Campo/Delete?Id='+payload.data.id);
        },
        async get(context:ActionContext<CampoState,any>,payload:any){
            let reponse=await Ajax.get('/api/services/app/Campo/GetCampoForEdit?Id='+payload.id);
            return reponse.data.result as Campo;
        },
        async getAllProperties(context: ActionContext<CampoState, any>, payload: any) {
             context.state.loading=true;
            let reponse=await Ajax.get('/api/services/app/Campo/GetAllProperties',{params:payload.data});
            context.state.loading=false;
            let page=reponse.data.result as PageResult<PropertyType>;
            context.state.listPropertyType=page.items;            
            return reponse.data.result as PropertyType;
        },
         async getAllRules(context: ActionContext<CampoState, any>, payload: any) {
             context.state.loading=true;
            let reponse=await Ajax.get('/api/services/app/Campo/GetAllRules',{params:payload.data});
            context.state.loading=false;
            let page=reponse.data.result as Result<Rules>;
            context.state.listRules=page.items;            
            return reponse.data.result as Rules;
        },
    };
    mutations={
        setCurrentPage(state:CampoState,page:number){
            state.currentPage=page;
        },
        setPageSize(state:CampoState,pagesize:number){
            state.pageSize=pagesize;
        },
        edit(state:CampoState,campo:Campo){
            state.editCampo=campo;
        }
    }
}
const campoModule=new CampoModule();
export default campoModule;