import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Ajax from '../../../lib/ajax'
import PageResult from '@/store/entities/page-result';
import ListMutations from '../list-mutations'
import MetodoEnvio from '@/store/entities/OZONE/metodoEnvio'
interface MetodoEnvioState extends ListState<MetodoEnvio>{
    editMetodoEnvio : MetodoEnvio
    
}
class MetodoEnvioMutations extends ListMutations<MetodoEnvio>{
    
}
class MetodoEnvioModule extends ListModule<MetodoEnvioState,any, MetodoEnvio>{
    state={
       totalCount :0,
        currentPage:1,
        pageSize:10,
        list: new Array<MetodoEnvio>(),
        loading:false,
        editMetodoEnvio :new MetodoEnvio(),

        
    }
    actions={
        async getAll(context:ActionContext<MetodoEnvioState,any>,payload:any){
            context.state.loading = true;
            let reponse=await Ajax.get('/api/services/app/MetodoEnvio/GetAll', { params:payload.data } );
            context.state.loading=false;
            let page=reponse.data.result as PageResult<MetodoEnvio>;
            context.state.totalCount=page.totalCount;
            context.state.list=page.items;
        },
          
        async create(context: ActionContext<MetodoEnvioState, any>, payload: any) {
            
            await Ajax.post('/api/services/app/MetodoEnvio/CreateOrEdit',payload.data);
            
        },
        async update(context:ActionContext<MetodoEnvioState,any>,payload:any){
            await Ajax.post('/api/services/app/MetodoEnvio/CreateOrEdit',payload.data);
        },
        async delete(context:ActionContext<MetodoEnvioState,any>,payload:any){
            await Ajax.delete('/api/services/app/MetodoEnvio/Delete?Id='+payload.data.id);
        },
        async get(context: ActionContext<MetodoEnvioState, any>, payload: any) {
            let response = await Ajax.get('/api/services/app/MetodoEnvio/GetGrupoForEdit?Id=' + payload.id);
            //context.state.editGrupo = response.data.result.grupo
            return response.data.result.grupo as MetodoEnvio;
        },
    };
    mutations={
        setCurrentPage(state:MetodoEnvioState,page:number){
            state.currentPage=page;
        },     
        setPageSize(state:MetodoEnvioState,pagesize:number){
            state.pageSize=pagesize;
        },
        edit(state:MetodoEnvioState,metodoEnvio:MetodoEnvio){
            
            state.editMetodoEnvio=metodoEnvio;          
        },
        
    }
    
}
const metodoEnvioModule=new MetodoEnvioModule();
export default metodoEnvioModule;