<template>
  <validation-provider :rules="rules" :name="name" v-bind="$attrs" v-slot="{ errors, valid, invalid, validated }">
          <a-form-item
      :label="label"
      :rules="rules"
      hasFeedback
      :validateStatus="
        valid && validated && successMessage
          ? 'success'
          : errors[0] && !error
          ? 'error'
          : invalid && validated
          ? 'warning'
          : null
      "
      :help="
        valid && validated && successMessage
          ? successMessage
          : errors[0] && !error
          ? errors[0]
          : null
      "
    >
      <a-date-picker
        v-model="valor"
        :disabled="disabled"
        :show-time="showTime ? {format: 'HH:mm'} : null" 
        placeholder="Seleccione fecha"
        :format="showTime ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY'" 
        @change="change" 
        style="width:100%"
      >
      </a-date-picker>
    </a-form-item>
  </validation-provider>
</template>
<script>
import moment from 'moment'
import { Moment } from 'moment'
import Datepicker from '@hokify/vuejs-datepicker'
import '@hokify/vuejs-datepicker/dist/vuejs-datepicker.css' // include CSS
import { defaults } from 'js-cookie'
export default {
  components: {
    Datepicker
  },
  inheritAttrs: false,
  name: 'base-date-picker',
  props: {
    required: {
      type: Boolean,
      description: 'Whether input is required (adds an asterix *)'
    },
    showTime: {
      type: Boolean,
      default: false,
      description: 'Whether it shows additional time selection.'
    },
    disabled: {
      type: Boolean,
      description: 'Whether input is disabled'
    },
    group: {
      type: Boolean,
      description: 'Whether input is an input group (manual override in special cases)'
    },
    alternative: {
      type: Boolean,
      description: 'Whether input is of alternative layout'
    },
    label: {
      type: String,
      description: 'Input label (text before input)'
    },
    error: {
      type: String,
      description: 'Input error (below input)'
    },
    successMessage: {
      type: String,
      description: 'Input success message',
      default: ''
    },
    labelClasses: {
      type: String,
      description: 'Input label css classes',
      default: 'form-control-label'
    },
    inputClasses: {
      type: String,
      description: 'Input css classes'
    },
    inputGroupClasses: {
      type: String,
      description: 'Input group css classes'
    },
    value: {
      type: [String, Number, Date, Object],
      description: 'Input value'
    },
    dateformatoptions: {
      type: Object,
      description: 'date-format-options',
      default: () => {
        return { year: 'numeric', month: 'numeric', day: 'numeric' }
      }
    },
    appendIcon: {
      type: String,
      description: 'Append icon (right)'
    },
    prependIcon: {
      type: String,
      description: 'Prepend icon (left)'
    },
    rules: {
      type: [String, Array, Object],
      description: 'Vee validate validation rules',
      default: ''
    },
    name: {
      type: String,
      description: 'Input name (used for validation)',
      default: ''
    },
    autocomplete: {
      type: String,
      description: 'Input autocomplete ',
      default: 'off'
    },
    locale: {
      type: String,
      description: 'Input locale ',
      default: 'es'
    }
  },
  data() {
    return {
      valor: this.value ? moment(this.value) : undefined,
      focused: false,
      labels: {
        es: {
          labelPrevDecade: 'Década anterior',
          labelPrevYear: 'Año anterior',
          labelPrevMonth: 'Mes anterior',
          labelCurrentMonth: 'Mes actual',
          labelNextMonth: 'Mes siguiente',
          labelNextYear: 'Año siguiente',
          labelNextDecade: 'Década siguiente',
          labelToday: 'Hoy',
          labelSelected: 'Fecha seleccionada',
          labelNoDateSelected: 'Fecha no seleccionada',
          labelCalendar: 'Calendario',
          labelNav: 'Navegación de calendario',
          labelHelp: 'Navegar por el calendario con las teclas de flecha'
        }
      }
    }
  },
  watch: {
    value: function (newVal, oldVal) {
      if (newVal !== null) {
        this.valor = moment(newVal) // moment(new Date(newVal, 'YYYY-MM-DD')).format('DD/MM/YYYY')
      } else {
        this.valor = null;
      }
    }
   },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        //input: this.updateValue,
        //focus: this.onFocus,
        //blur: this.onBlur
      }
    },
    slotData() {
      return {
        focused: this.focused,
        error: this.error,
        ...this.listeners
      }
    },
    hasIcon() {
      const { append, prepend } = this.$slots
      return append !== undefined || prepend !== undefined || this.appendIcon !== undefined || this.prependIcon !== undefined || this.group
    }
  },
  methods: {
    updateValue(evt) {
      let value = evt
      if (value && value !== null) {
        this.$emit('input', moment(value).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'))
      }
    },
    onFocus(evt) {
      this.focused = true
      this.$emit('focus', evt)
    },
    onBlur(evt) {
      this.focused = false
      this.$emit('blur', evt)
    },
    change(value) {
      if (value && value !== null) {
        //this.$emit('input', moment(value).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'))
        this.$emit('input', moment(value).toISOString())
      }
    },
    parseTypedDate(input) {
      let momentDate = moment(input, 'DD/MM/YYYY')
      return momentDate.toDate()
    }
  }
}
</script>
<style scoped>
.mx-datepicker {
  position: relative;
  display: inline-block;
  width: 100%;
}
</style>
