import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Ajax from '../../../lib/ajax'
import PageResult from '@/store/entities/page-result';
import ListMutations from '../list-mutations'
import Cliente from '@/store/entities/OZONE/cliente'
import cliente from './../../entities/OZONE/cliente';

interface ClienteState extends ListState<Cliente>{
    editCliente:Cliente,
    selected: {},
}
class ClienteMutations extends ListMutations<Cliente>{

}
class ClienteModule extends ListModule<ClienteState,any, Cliente>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize:10,
        list: new Array< Cliente>(),
        loading:false,
        editCliente: new  Cliente(),
        selected: {},
    }
    actions={
        async getAll(context:ActionContext<ClienteState,any>,payload:any){
            context.state.loading = true;
            let reponse=await Ajax.get('/api/services/app/Cliente/GetAll',{params:payload.data});
            context.state.loading=false;
            let page=reponse.data.result as PageResult<Cliente>;
            context.state.totalCount=page.totalCount;
            context.state.list=page.items;
        },
        async getAllWithImages(context:ActionContext<ClienteState,any>,payload:any){
            context.state.loading=true;
            let reponse=await Ajax.get('/api/services/app/cliente/GetAllWithImages',{params:payload.data});
            context.state.loading=false;
            let page=reponse.data.result as PageResult<Cliente>;
            context.state.totalCount=page.totalCount;
            context.state.list=page.items;
        },
        async create(context: ActionContext<ClienteState, any>, payload: any) {
            await Ajax.post('/api/services/app/Cliente/CreateOrEdit',payload.data);
        },
        async update(context:ActionContext<ClienteState,any>,payload:any){
            await Ajax.post('/api/services/app/Cliente/CreateOrEdit',payload.data);
        },
        async delete(context:ActionContext<ClienteState,any>,payload:any){
            await Ajax.delete('/api/services/app/Cliente/Delete?Id='+payload.data.id);
        },
        async get(context: ActionContext<ClienteState, any>, payload: any) {
            let response = await Ajax.get('/api/services/app/Cliente/GetClienteForEdit?Id=' + payload.id);
            context.state.editCliente = response.data.result.cliente
            return response.data.result.cliente as Cliente;
        },
        async deleteClienteFileAsync(context:ActionContext<ClienteState,any>,payload:any){
            await Ajax.post('/api/services/app/Cliente/DeleteMarcaFile',payload.data);
        },
    };
    mutations={
        setCurrentPage(state:ClienteState,page:number){
            state.currentPage = page;
        },
        setPageSize(state:ClienteState,pagesize:number){
            state.pageSize = pagesize;
        },
        edit(state:ClienteState, cliente: Cliente){
            state.editCliente = cliente;
        },
        select(state: ClienteState, cliente: any){
            state.selected = cliente;
        }
    }
}
const clienteModule=new ClienteModule();
export default clienteModule;