import Entity from '../entity'
import Campana from "./campana";

export default class ConfiguracionSorteoCampana extends Entity<number>{

    campanaId : number;
    fechaInicio : Date;
    fechaFin : Date;
    active: boolean;
    permiteMultiplesSorteos:boolean;
    permiteRepetirGanador:boolean;
    numeroGanadores: number = 1;
    numeroSuplentes: number = 0;
}