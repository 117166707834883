import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Ajax from '../../../lib/ajax'
import PageResult from '@/store/entities/page-result';
import ListMutations from '../list-mutations'
import Tienda from '@/store/entities/OZONE/tienda'

interface TiendaState extends ListState<Tienda>{
    editTienda : Tienda
    
}
class TiendaMutations extends ListMutations<Tienda>{
    
}
class TiendaModule extends ListModule<TiendaState,any, Tienda>{
    state={
       totalCount :0,
        currentPage:1,
        pageSize:10,
        list: new Array<Tienda>(),
        loading:false,
        editMetodoEnvio :new Tienda(),

        
    }
    actions={
        async getAll(context:ActionContext<TiendaState,any>,payload:any){
            context.state.loading = true;
            let reponse=await Ajax.get('/api/services/app/Tiendas/GetAll', { params:payload.data } );
            context.state.loading=false;
            let page=reponse.data.result as PageResult<Tienda>;
            context.state.totalCount=page.totalCount;
            context.state.list=page.items;
        },
          
        async create(context: ActionContext<TiendaState, any>, payload: any) {
            
            await Ajax.post('/api/services/app/Tiendas/CreateOrEdit',payload.data);
            
        },
        async update(context:ActionContext<TiendaState,any>,payload:any){
            await Ajax.post('/api/services/app/Tiendas/CreateOrEdit',payload.data);
        },
        async delete(context:ActionContext<TiendaState,any>,payload:any){
            await Ajax.delete('/api/services/app/Tiendas/Delete?Id='+payload.data.id);
        },
        async get(context: ActionContext<TiendaState, any>, payload: any) {
            let response = await Ajax.get('/api/services/app/Tiendas/GetGrupoForEdit?Id=' + payload.id);
            //context.state.editGrupo = response.data.result.grupo
            return response.data.result.grupo as Tienda;
        },
    };
    mutations={
        setCurrentPage(state:TiendaState,page:number){
            
            state.currentPage=page;
        },     
        setPageSize(state:TiendaState,pagesize:number){
            
            state.pageSize=pagesize;
        },
        edit(state:TiendaState,tienda:Tienda){
            
            state.editTienda=tienda;          
        },
        
    }
    
}
const tiendaModule=new TiendaModule();
export default tiendaModule;