import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Ajax from '../../../lib/ajax'
import PageResult from '@/store/entities/page-result';
import ListMutations from '../list-mutations'
import EstadoEnvio from './../../entities/OZONE/estadoEnvioOzone';

interface EstadoEnvioState  {
    editEstadoCampana: EstadoEnvio,
    list: Array<EstadoEnvio>,
    loading: boolean
}
class EstadoEnvioMutations extends ListMutations<EstadoEnvio>{

}
class EstadoEnvioModule implements Module<EstadoEnvioState, EstadoEnvio>{
    namespaced=true;
    state = {
        list: new Array<EstadoEnvio>(),
        loading: false,
        editEstadoCampana: new EstadoEnvio(),
    }
    actions={
        async getAll(context:ActionContext<EstadoEnvioState,any>, payload: any){
            context.state.loading = true;
            let response = await Ajax.get('/api/services/app/EstadoEnvio/GetAll');
            context.state.loading = false;
            context.state.list = response.data.result;
        }
    };
}
const estadoEnvioModule = new EstadoEnvioModule();
export default estadoEnvioModule;