import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Ajax from '../../../lib/ajax'
import PageResult from '@/store/entities/page-result';
import ListMutations from '../list-mutations'
import Marca from '@/store/entities/OZONE/marca'
import AppConsts from './../../../lib/appconst';

interface MarcaState extends ListState<Marca>{
    editMarca:Marca,
}
class MarcaMutations extends ListMutations<Marca>{

}
class MarcaModule extends ListModule<MarcaState, any, Marca>{    
    state={
        totalCount:0,
        currentPage:1,
        pageSize:10,
        list: new Array<Marca>(),
        loading:false,
        editMarca:new  Marca(),
    }
    actions={
        async getAll(context:ActionContext<MarcaState,any>,payload:any){
            context.state.loading=true;
            let reponse=await Ajax.get('/api/services/app/Marca/GetAll',{params:payload.data});
            context.state.loading=false;
            let page=reponse.data.result as PageResult<Marca>;
            context.state.totalCount=page.totalCount;
            context.state.list=page.items;
        },
        async getAllWithImages(context:ActionContext<MarcaState,any>,payload:any){
            context.state.loading=true;
            let reponse=await Ajax.get('/api/services/app/Marca/GetAllWithImages',{params:payload.data});
            context.state.loading=false;
            let page=reponse.data.result as PageResult<Marca>;
            context.state.totalCount=page.totalCount;
            context.state.list=page.items;
        },
        async create(context: ActionContext<MarcaState, any>, payload: any) {
            await Ajax.post('/api/services/app/Marca/CreateOrEdit',payload.data);
        },
        async update(context:ActionContext<MarcaState,any>,payload:any){
            await Ajax.post('/api/services/app/Marca/CreateOrEdit',payload.data);
        },
        async delete(context:ActionContext<MarcaState,any>,payload:any){
            await Ajax.delete('/api/services/app/Marca/Delete?Id='+payload.data.id);
        },
        async get(context: ActionContext<MarcaState, any>, payload: any) {
            context.state.loading=true;
            let response = await Ajax.get('/api/services/app/Marca/GetMarcaForEdit?Id=' + payload.id);
            context.state.loading=false;
            context.state.editMarca = response.data.result.marca
            return response.data.result.marca as Marca;
        },
        async deleteMarcaFileAsync(context:ActionContext<MarcaState,any>,payload:any){
            await Ajax.post('/api/services/app/Marca/EraseMarcaFile',payload.data);
        },
    };
    mutations={
        setCurrentPage(state:MarcaState,page:number){
            state.currentPage=page;
        },
        setPageSize(state:MarcaState,pagesize:number){
            state.pageSize=pagesize;
        },
        edit(state: MarcaState, marca: Marca) {            
            state.editMarca=marca;
        }
    }
}
const marcaModule=new MarcaModule();
export default marcaModule;