import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Ajax from '../../../lib/ajax'
import PageResult from '@/store/entities/page-result';
import ListMutations from '../list-mutations'
import {Grupo , GrupoTree} from '@/store/entities/OZONE/grupo'
import Campo from './../../entities/OZONE/campo';

interface GrupoState extends ListState<Grupo>{
    editGrupo:Grupo,
    camposList: Array<Campo>,
    camposListPaged: Array<Campo>,
    totalCountCampos: number,
    currentPageCampos: number,
    pageSizeCampos: number
    grupoTree:Array<GrupoTree>
}
class GrupoMutations extends ListMutations<Grupo>{

}
class GrupoModule extends ListModule<GrupoState,any, Grupo>{
    state={
       totalCount :0,
        currentPage:1,
        pageSize:10,
        list: new Array<Grupo>(),
        loading:false,
        editGrupo: new Grupo(),
        camposList: new Array<Campo>(),
        camposListPaged: new Array<Campo>(),
        totalCountCampos:0,
        currentPageCampos:1,
        pageSizeCampos:10,
        grupoTree: new Array<GrupoTree>()
        
    }
    actions={
        async getAll(context:ActionContext<GrupoState,any>,payload:any){
            context.state.loading = true;
            let reponse=await Ajax.get('/api/services/app/Grupo/GetAll',{params:payload.data});
            context.state.loading=false;
            let page=reponse.data.result as PageResult<Grupo>;
            context.state.totalCount=page.totalCount;
            context.state.list=page.items;
        },
        async getAllCampos(context:ActionContext<GrupoState,any>,payload:any){
            context.state.loading=true;
            let reponse=await Ajax.get('/api/services/app/Grupo/GetAllCampos',{params:payload.data});
            context.state.loading=false;
            let page = reponse.data.result as PageResult<Campo>;
            context.state.totalCountCampos = page.totalCount;
            context.state.camposList = page.items;
        },              
        async getAllPagedCampos(context:ActionContext<GrupoState,any>,payload:any){
            context.state.loading=true;
            let reponse=await Ajax.get('/api/services/app/Grupo/GetAllCampos',{params:payload.data});
            context.state.loading=false;
            let page = reponse.data.result as PageResult<Campo>;
            context.state.totalCountCampos = page.totalCount;
            context.state.camposListPaged=page.items;
        },
        async getAllForTree(context:ActionContext<GrupoState,any>,payload:any){
            context.state.loading = true;
            let reponse = await Ajax.get('/api/services/app/Grupo/GetAllForTree',{params:payload.data} );
            context.state.loading=false;
            let page=reponse.data.result as PageResult<GrupoTree>;
            context.state.totalCount=page.totalCount;
            context.state.grupoTree=page.items;
             
        },             
        async create(context: ActionContext<GrupoState, any>, payload: any) {
            
            await Ajax.post('/api/services/app/Grupo/CreateOrEdit',payload.data);
            

        },
        async update(context:ActionContext<GrupoState,any>,payload:any){
            await Ajax.post('/api/services/app/Grupo/CreateOrEdit',payload.data);
        },
        async delete(context:ActionContext<GrupoState,any>,payload:any){
            await Ajax.delete('/api/services/app/Grupo/Delete?Id='+payload.data.id);
        },
        async get(context: ActionContext<GrupoState, any>, payload: any) {
            let response = await Ajax.get('/api/services/app/Grupo/GetGrupoForEdit?Id=' + payload.id);
            context.state.editGrupo = response.data.result.grupo
            return response.data.result.grupo as Grupo;
        },
    };
    mutations={
        setCurrentPage(state:GrupoState,page:number){
            state.currentPage=page;
        },
        setCurrentPageCampos(state:GrupoState,page:number){
            state.currentPageCampos=page;
        },
        setPageSize(state:GrupoState,pagesize:number){
            state.pageSize=pagesize;
        },
        edit(state:GrupoState,grupo:Grupo){
            state.editGrupo=grupo;
        },
        addCampos(state: GrupoState, campo: Campo) {    
            state.camposList.push(campo);
        },
        deleteCampos(state: GrupoState, campo: Campo) {    
            state.camposList.splice(state.camposList.indexOf(campo), 1);
        },
        cleanCampos(state: GrupoState){
            state.camposList =  new Array<Campo>();
        }
      
    }
}
const grupoModule=new GrupoModule();
export default grupoModule;