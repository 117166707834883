import { Store, Module, ActionContext } from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Ajax from '../../../lib/ajax'
import PageResult from '@/store/entities/page-result';
import ListMutations from '../list-mutations'
import Deduplicacion from '../../entities/OZONE/deduplicacion'
import File from '../../entities/OZONE/file'
import PampersModel from '../../entities/OZONE/pampersModel'
import DeduplicationData from '../../entities/OZONE/deduplicationData'
import DeduplicationInformation from '../../entities/OZONE/deduplicationInformation'

interface DeduplicacionState extends ListState<Deduplicacion> {
    editDeduplicacion: Deduplicacion,
    pendingFiles: Array<File>,
    information: DeduplicationInformation
}
class DeduplicacionMutations extends ListMutations<Deduplicacion>{

}
class DeduplicacionModule extends ListModule<DeduplicacionState, any, Deduplicacion>{
    state = {
        totalCount: 0,
        currentPage: 1,
        pageSize: 10,
        list: new Array<Deduplicacion>(),
        loading: false,
        editDeduplicacion: new Deduplicacion(),
        pendingFiles: new Array<File>()
    }
    actions = {
        async getPendingFiles(context: ActionContext<DeduplicacionState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/services/app/Deduplicacion/GetPendingFiles');
            context.state.loading = false;

            let result = reponse.data.result;
            context.state.pendingFiles = result.pending as Array<File>;
        },
        async startProcess(context: ActionContext<DeduplicacionState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.post('/api/services/app/Deduplicacion/StartProcess', payload.data, { timeout: 0 });
            context.state.loading = false;

            let result = reponse.data.result as DeduplicationInformation;
            context.state.information = result;
            //return result;

        },
        async deduplicateSelf(context: ActionContext<DeduplicacionState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.post('/api/services/app/Deduplicacion/DeduplicateSelf', payload.data, { timeout: 0 });
            context.state.loading = false;

            let result = reponse.data.result as Array<PampersModel>;
            return result;
        },
        async deduplicateBBDD(context: ActionContext<DeduplicacionState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.post('/api/services/app/Deduplicacion/DeduplicateBBDD', payload.data, { timeout: 0 });
            context.state.loading = false;

            let result = reponse.data.result as Array<PampersModel>;
            return result;

        },
        async loadProcess(context: ActionContext<DeduplicacionState, any>, payload: any) {
            context.state.loading = true;
            await Ajax.post('/api/services/app/Deduplicacion/LoadProcess', payload.data, { timeout: 0 });
            context.state.loading = false;

        },
    };
    mutations = {
        setCurrentPage(state: DeduplicacionState, page: number) {
            //state.currentPage = page;
        },
        setPageSize(state: DeduplicacionState, pagesize: number) {
            //state.pageSize = pagesize;
        },
        setSampling(state: DeduplicacionState, data: Array<PampersModel>) {
            state.information.sampling.data = data;
        },
        setNeed(state: DeduplicacionState, data: Array<PampersModel>) {
            state.information.welcome.data = data;
        },
    }
}
const deduplicacionModule = new DeduplicacionModule();
export default deduplicacionModule;