import { Store, Module, ActionContext } from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Ajax from '../../../lib/ajax'
import PageResult from '@/store/entities/page-result';
import ListMutations from '../list-mutations'
import Campana from '@/store/entities/OZONE/campana'
import Campo from './../../entities/OZONE/campo';
import Ean from './../../entities/OZONE/ean';
import ReglasTicketDato from '@/store/entities/OZONE/reglasTicketDato';
import ReglasTicketType from '@/store/entities/OZONE/reglasTicketType';
import CallCenterCampana from '../../entities/OZONE/callCenterCampana';
import ProgramacionTransferenciaCampana from '@/store/entities/OZONE/programacionTransferencia';
import ValidateImagenCampana from '@/store/entities/OZONE/validateImagenCampana';
import Regalo from '@/store/entities/OZONE/regalo'
import ProgramacionEnviosCampana from '@/store/entities/OZONE/programacionEnvios';
import ControlDuplicadosDato from '@/store/entities/OZONE/controlDuplicadosDato';
import DocumentoLegal from '@/store/entities/OZONE/documentoLegal';
import ConfiguracionSorteoCampana from '@/store/entities/OZONE/configuracionSorteoCampana';

interface CampanaState extends ListState<Campana> {
    editCampana: Campana,
    camposList: Array<Campo>,
    eansList: Array<Ean>,
    camposListPaged: Array<Campo>,
    totalCountCampos: number,
    totalCountEans: number,
    currentPageCampos: number,
    pageSizeCampos: number,
    reglasTicketDato: Array<ReglasTicketDato>,
    selects: Array<Campana>,
    listPropertyType: Array<ReglasTicketType>
    programacion: ProgramacionTransferenciaCampana,
    programacionEnvio: ProgramacionEnviosCampana,
    validateImagenCampana: ValidateImagenCampana,
    regalosList:Array<Regalo>,
    controlDuplicadosDato: Array<ControlDuplicadosDato>
    documentos: Array<DocumentoLegal>,
    configuracionSorteo:ConfiguracionSorteoCampana
}
class EstadoCampanaMutations extends ListMutations<Campana>{

}
class CampanaModule extends ListModule<CampanaState, any, Campana>{
    state = {
        totalCount: 0,
        currentPage: 1,
        pageSize: 10,
        list: new Array<Campana>(),
        selects: new Array<Campana>(),
        eansList: Array<Ean>(),
        loading: false,
        editCampana: new Campana(),
        camposList: new Array<Campo>(),
        camposListPaged: new Array<Campo>(),
        totalCountCampos: 0,
        totalCountEans: 0,
        currentPageCampos: 1,
        pageSizeCampos: 10,
        reglasTicketDato: new Array<ReglasTicketDato>(),
        listPropertyType: new Array<ReglasTicketType>(),
        programacion: new ProgramacionTransferenciaCampana(),
        programacionEnvio: new ProgramacionEnviosCampana(),
        configuracionSorteo: new ConfiguracionSorteoCampana(),
        validateImagenCampana : new ValidateImagenCampana(),
        regalosList: Array<Regalo>(),
        controlDuplicadosDato: new Array<ControlDuplicadosDato>(),
        documentos: new Array<ControlDuplicadosDato>()
    }
    actions = {
        async getAll(context: ActionContext<CampanaState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.post('/api/services/app/Campana/FindAll', payload.data);
            context.state.loading = false;
            let page = reponse.data.result as PageResult<Campana>;
            context.state.totalCount = page.totalCount;
            context.state.list = page.items;
        },
        async getSelect(context: ActionContext<CampanaState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/services/app/Campana/GetSelect', { params: payload.data });
            context.state.loading = false;
            let page = reponse.data.result as PageResult<Campana>;
            context.state.selects = page.items;
        },
        async getAllCampos(context: ActionContext<CampanaState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/services/app/Campana/GetAllCampos', { params: payload.data });
            context.state.loading = false;
            let page = reponse.data.result as PageResult<Campo>;
            context.state.totalCountCampos = page.totalCount;
            context.state.camposList = page.items;
        },
        async getControlDuplicadosParticipation(context: ActionContext<CampanaState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.get('/api/services/app/Campana/GetControlDuplicadosParticipation', { params: payload.data });
            let items = response.data.result as Array<ControlDuplicadosDato>;
            context.state.loading = false;
            return items;
        },
        async getAllEans(context: ActionContext<CampanaState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/services/app/Campana/GetAllEans', { params: payload.data });
            context.state.loading = false;
            let page = reponse.data.result as PageResult<Ean>;
            context.state.totalCountEans = page.totalCount;
            context.state.eansList = page.items;
            
        },
        async getAllRegalo(context: ActionContext<CampanaState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/services/app/Campana/GetAllRegalos', { params: payload.data });
            context.state.loading = false;
            let page = reponse.data.result as PageResult<Regalo>;
            context.state.totalCountEans = page.totalCount;
            context.state.regalosList = page.items;
            
        },
        async getAllPagedCampos(context: ActionContext<CampanaState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/services/app/Campana/GetAllCampos', { params: payload.data });
            context.state.loading = false;
            let page = reponse.data.result as PageResult<Campo>;
            context.state.totalCountCampos = page.totalCount;
            context.state.camposListPaged = page.items;
        },
        async GetAllReglasTicket(context: ActionContext<CampanaState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/services/app/Campana/GetAllReglasTicket', { params: payload.data });
            context.state.loading = false;
            let items = reponse.data.result as Array<ReglasTicketDato>;
            context.state.reglasTicketDato = items;
            return items;
        },
        async GetAllReglasTicketForEdit(context: ActionContext<CampanaState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/services/app/Campana/GetAllReglasTicketForEdit', { params: payload.data });
            context.state.loading = false;
            let items = reponse.data.result as Array<ReglasTicketDato>;
            context.state.reglasTicketDato = items;
            return items;
        },
        async GetAllControlDuplicadosForEdit(context: ActionContext<CampanaState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/services/app/Campana/GetAllControlDuplicadosDato', { params: payload.data });
            context.state.loading = false;
            let items = reponse.data.result as Array<ControlDuplicadosDato>;
            context.state.controlDuplicadosDato = items;
            return items;
        },
        async GetAllReglasCampo(context: ActionContext<CampanaState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/services/app/Campana/GetAllReglasCampo', { params: payload.data });
            context.state.loading = false;
            let items = reponse.data.result as Array<ReglasTicketDato>;
            context.state.reglasTicketDato = items;
            return items;
        },
        async getProgramacionTransferencia(context: ActionContext<CampanaState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/services/app/Campana/GetProgramacionTransferencia?campanaId=' + payload.id);
            context.state.loading = false;
            let items = reponse.data.result as ProgramacionTransferenciaCampana;
            context.state.programacion = items;
            return items;
        },
        async getvalidateImagenCampana(context: ActionContext<CampanaState, any>, payload: any) {
            
            context.state.loading = true;
            let reponse = await Ajax.get('/api/services/app/Campana/GetValidationImagenCampana?campanaId=' + payload.id);
            context.state.loading = false;

            if(reponse && reponse.data && reponse.data.result){
                let items = reponse.data.result as ValidateImagenCampana;
                context.state.validateImagenCampana = items;
                return items;
            }
            else{
                return null;
            }
        },
        async updateProgramacionTransferencia(context: ActionContext<CampanaState, any>, payload: any) {
            let reponse = await Ajax.post('/api/services/app/Campana/CreateOrEditProgramacionTransferencia', payload.data);
        },
        async configurarSorteoCampana(context: ActionContext<CampanaState, any>, payload: any) {
            await Ajax.post('/api/services/app/Campana/CreateorEditConfiguracionSorteo', payload.data);
        },
        async deleteConfiguracionSorteo(context: ActionContext<CampanaState, any>, payload: any) {
            await Ajax.delete('/api/services/app/Campana/DeleteConfiguracionSorteo?CampanaId=' + payload.campanaId);
        },
        async getConfiguracionSorteo(context: ActionContext<CampanaState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/services/app/Campana/GetConfiguracionSorteo?campanaId=' + payload.id);
            context.state.loading = false;
            let items = reponse.data.result as ConfiguracionSorteoCampana;
            context.state.configuracionSorteo = items;
            return items;
        },
        async realizarSorteo({ commit }, item) {
            const response = await Ajax.post('/api/services/app/Campana/RealizarSorteo', item.data            );
            return response.data;
        },
        async recuperarSorteo({ commit }, item) {
            const response = await Ajax.get('/api/services/app/Campana/RecuperarSorteos?campanaId=' + item.data.id);
            return response.data;
        },
        //generarSorteo
        //recuperarSorteo


        async updatealidateImagenCampana(context: ActionContext<CampanaState, any>, payload: any) {
            let reponse = await Ajax.post('/api/services/app/Campana/CreateorEditValidationImagenCampana', payload.data);

        },
        async deleteProgramacionTransferencia(context: ActionContext<CampanaState, any>, payload: any) {
            await Ajax.delete('/api/services/app/Campana/DeleteProgramacionTransferencia?CampanaId=' + payload.idCampana);
        },
        async getAllDocumentos(context:ActionContext<CampanaState,any>,payload:any){
            
            context.state.loading = true;
            let reponse = await Ajax.get('/api/services/app/Campana/GetAllDocumentos', {params:payload.data});
            context.state.loading = false;
            let page = reponse.data.result as PageResult<DocumentoLegal>;
            context.state.documentos = page.items;
        },
        async updateDocumentoLegal(context: ActionContext<CampanaState, any>, payload: any) {
            let reponse = await Ajax.post('/api/services/app/Campana/CreateOrEditDocumentoLegal', payload.data);
        },
        async deleteDocumentoLegal(context: ActionContext<CampanaState, any>, payload: any) {
            //let reponse = await Ajax.post('/api/services/app/Campana/DeleteProgramacionTransferencia', payload.data);
            await Ajax.delete('/api/services/app/Campana/DeleteDocumentoLegal?Id=' + payload.data.id);
        },
        async getAllProperties(context: ActionContext<CampanaState, any>, payload: any) {
            context.state.loading=true;
            let reponse=await Ajax.get('/api/services/app/Campana/GetAllProperties',{params:payload.data});
            context.state.loading=false;
            let page=reponse.data.result as PageResult<ReglasTicketType>;
            context.state.listPropertyType=page.items;            
            return reponse.data.result as ReglasTicketType;
        },
        async create(context: ActionContext<CampanaState, any>, payload: any) {
            let response = await Ajax.post('/api/services/app/Campana/CreateOrEdit', payload.data);
            return response.data.result
            //payload.data.concurrencyStamp = response.data.result
        },
        async createReglasTicketDato(context: ActionContext<CampanaState, any>, payload: any) {
            await Ajax.post('/api/services/app/Campana/CreateOrEditReglasTicketDato', payload.data);
        },
        async update(context: ActionContext<CampanaState, any>, payload: any) {
            let response = await Ajax.post('/api/services/app/Campana/CreateOrEdit', payload.data);
            
            payload.data.concurrencyStamp = response.data.result
            
        },
        async updateReglasTicketDato(context: ActionContext<CampanaState, any>, payload: any) {
            
            await Ajax.post('/api/services/app/Campana/CreateOrEditReglasTicketDato', payload.data);
        },
        async updateControlDuplicadosDato(context: ActionContext<CampanaState, any>, payload: any) {
            await Ajax.post('/api/services/app/Campana/CreateOrEditControlDuplicadoDato', payload.data, {timeout: 100000});
        },
        async deleteControlDuplicadosDato(context: ActionContext<CampanaState, any>, payload: any) {
            await Ajax.delete('/api/services/app/Campana/DeleteControlDuplicadosDato?Id=' + payload.data.id);
        },
        async delete(context: ActionContext<CampanaState, any>, payload: any) {
            await Ajax.delete('/api/services/app/Campana/Delete?Id=' + payload.data.id);
        },
        async deleteReglasTicketDato(context: ActionContext<CampanaState, any>, payload: any) {
            context.state.reglasTicketDato.splice(context.state.reglasTicketDato.indexOf(context.state.reglasTicketDato.find(x=>x.id === payload.data.id)), 1);
            await Ajax.delete('/api/services/app/Campana/DeleteReglasTicketDato?Id=' + payload.data.id);
        },
        async get(context: ActionContext<CampanaState, any>, payload: any) {
            let response = await Ajax.get('/api/services/app/Campana/GetCampanaForEdit?Id=' + payload.id);
            context.state.editCampana = response.data.result.campana
            return response.data.result.campana as Campana;
            
        },
        async getForView(context: ActionContext<CampanaState, any>, payload: any) {
            let response = await Ajax.get('/api/services/app/Campana/GetCampanaForView?Id=' + payload.id);
            context.state.editCampana = response.data.result.campana
            return response.data.result.campana as Campana;            
        },
        async clone(context: ActionContext<CampanaState, any>, payload: any) {
            let response = await Ajax.post('/api/services/app/Campana/Clone', payload.data);
            context.state.editCampana = response.data.result.campana
            return response.data.result.campana as Campana;
        },
        async createProgramacionTransferencias(context: ActionContext<CampanaState, any>, payload: any) {
            await Ajax.post('/api/services/app/Campana/CreateOrEditProgramacionTransferencia', payload.data);
        },
        async updateProgramacionTransferencias(context: ActionContext<CampanaState, any>, payload: any) {
            await Ajax.post('/api/services/app/Campana/CreateOrEditProgramacionTransferencia', payload.data);
        },
        async updateProgramacionEnvios(context: ActionContext<CampanaState, any>, payload: any) {
            await Ajax.post('/api/services/app/Campana/CreateOrEditProgramacionEnvio', payload.data);
        },
        async deleteProgramacionEnvios(context: ActionContext<CampanaState, any>, payload: any) {
            await Ajax.delete('/api/services/app/Campana/DeleteProgramacionEnvios', { params: payload.data });
        },
        async getProgramacionEnvios(context: ActionContext<CampanaState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/services/app/Campana/GetProgramacionEnvio', { params: payload.data });
            context.state.loading = false;
            let items = reponse.data.result as ProgramacionEnviosCampana;
            context.state.programacionEnvio = items;
            return items;
        },
    };
    mutations = {
        setCurrentPage(state: CampanaState, page: number) {
            state.currentPage = page;
        },
        setCurrentPageCampos(state: CampanaState, page: number) {
            state.currentPageCampos = page;
        },
        setPageSize(state: CampanaState, pagesize: number) {
            state.pageSize = pagesize;
        },
        setValidateImagenCampana(state: CampanaState, validateImagenCampana: ValidateImagenCampana){
            state.editCampana.validateImagenCampana = validateImagenCampana;
        },
        edit(state: CampanaState, campana: Campana) {
            state.editCampana = campana;
        },
        addCampos(state: CampanaState, campo: Campo) {
            state.camposList.push(campo);
        },
        deleteCampos(state: CampanaState, campo: Campo) {
            state.camposList.splice(state.camposList.indexOf(campo), 1);
        },
        addReglasTicketDato(state: CampanaState) {
            state.reglasTicketDato.push(new ReglasTicketDato());
        },
        deleteReglasTicketDato(state: CampanaState, reglasTicketDato: ReglasTicketDato) {
            state.reglasTicketDato.splice(state.reglasTicketDato.indexOf(reglasTicketDato), 1);
        },
        addCampoRule(state: CampanaState, reglaCampo: ReglasTicketDato) {
            
            let campo = state.camposList.find((y) => y.id === reglaCampo.campoId)
            if (campo) {
                let selectedRules = JSON.parse(campo.listSelectedRules)
                if (reglaCampo.regla !== null && reglaCampo.regla !== '') {
                    let newRegla = JSON.parse(reglaCampo.regla)
                    selectedRules.push(newRegla)
                    campo.listSelectedRules = JSON.stringify(selectedRules)
                }
            }
        },
        editCallCenter(state: CampanaState, callCenterCampana: CallCenterCampana) {
            
            state.editCampana.vozitelCampana = callCenterCampana
        }
    }
}
const campanaModule = new CampanaModule();
export default campanaModule;