import { Store, Module, ActionContext } from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Ajax from '../../../lib/ajax'
import PageResult from '@/store/entities/page-result';
import ListMutations from '../list-mutations'
import Ean from '@/store/entities/OZONE/ean'
import ean from '../../entities/OZONE/ean';

interface EanState extends ListState<Ean> {
    editEan: Ean,
}
class EanMutations extends ListMutations<Ean>{

}
class EanModule extends ListModule<EanState, any, Ean>{
    state = {
        totalCount: 0,
        currentPage: 1,
        pageSize: 10,
        list: new Array<Ean>(),
        loading: false,
        editEan: new Ean(),

    }
    actions = {
        async getAll(context: ActionContext<EanState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/services/app/Ean/GetAll', payload.data.id);
            context.state.loading = false;
            let page = reponse.data.result as PageResult<Ean>;
            context.state.totalCount = page.totalCount;
            context.state.list = page.items;
          
        },
        async create(context: ActionContext<EanState, any>, payload: any) {
            await Ajax.post('/api/services/app/Ean/CreateOrEdit', payload.data);
        },
        async update(context: ActionContext<EanState, any>, payload: any) {
            await Ajax.post('/api/services/app/Ean/CreateOrEdit', payload.data);
        },
        async delete(context: ActionContext<EanState, any>, payload: any) {
            await Ajax.delete('/api/services/app/Ean/Delete?Id=' + payload.data.id);
        },
        async deleteAll(context: ActionContext<EanState, any>, payload: any){
            await Ajax.delete('/api/services/app/Ean/DeleteAllEans?campanaId='+payload.data);
        },
        async leerExcell(context: ActionContext<EanState, any>, payload: any) {
            await Ajax.post('/api/services/app/Ean/InsertarEans', payload.data);
        },
        async get(context: ActionContext<EanState, any>, payload: any) {
            let response = await Ajax.get('/api/services/app/Ean/GetEanForEdit?Id=' + payload.id);
            context.state.editEan = response.data.result.ean
            return response.data.result.ean as Ean;
        },
        async getAllEans(context: ActionContext<EanState, any>, payload: any) {
            let response = await Ajax.get('/api/services/app/Campana/GetAllEans',{params:payload.data});
            context.state.editEan = response.data.result.ean
            return response.data.result.ean as Ean;
        },
    };
    mutations = {
        setCurrentPage(state: EanState, page: number) {
            state.currentPage = page;
        },
        setPageSize(state: EanState, pagesize: number) {
            state.pageSize = pagesize;
        },
        edit(state: EanState, ean: Ean) {
            state.editEan = ean;
        }
    }
}
const eanModule = new EanModule();
export default eanModule;