import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Ajax from '../../../lib/ajax'
import PageResult from '@/store/entities/page-result';
import ListMutations from '../list-mutations'
import EmailEstadoParticipacion from '@/store/entities/OZONE/emailEstadoParticipacion'

interface EmailEstadoParticipacionState extends ListState<EmailEstadoParticipacion>{
    editEmailEstadoParticipacion:EmailEstadoParticipacion,
}
class EstadoEmailEstadoParticipacionMutations extends ListMutations<EmailEstadoParticipacion>{

}
class EmailEstadoParticipacionModule extends ListModule<EmailEstadoParticipacionState,any, EmailEstadoParticipacion>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize:10,
        list: new Array<EmailEstadoParticipacion>(),
        loading:false,
        editEmailEstadoParticipacion:new  EmailEstadoParticipacion(),
    }
    actions={
        async getAll(context:ActionContext<EmailEstadoParticipacionState,any>,payload:any){
            
            context.state.loading=true;
            let reponse=await Ajax.get('/api/services/app/EmailEstadoParticipacion/GetAll',{params:payload.data});
            context.state.loading=false;
            let page=reponse.data.result as PageResult<EmailEstadoParticipacion>;
            context.state.totalCount=page.totalCount;
            context.state.list=page.items;
        },
        async create(context: ActionContext<EmailEstadoParticipacionState, any>, payload: any) {
            await Ajax.post('/api/services/app/EmailEstadoParticipacion/CreateOrEdit',payload.data);
        },
        async update(context:ActionContext<EmailEstadoParticipacionState,any>,payload:any){
            await Ajax.post('/api/services/app/EmailEstadoParticipacion/CreateOrEdit',payload.data);
        },
        async delete(context:ActionContext<EmailEstadoParticipacionState,any>,payload:any){
            await Ajax.delete('/api/services/app/EmailEstadoParticipacion/Delete?Id='+payload.data.id);
        },
        async get(context:ActionContext<EmailEstadoParticipacionState,any>,payload:any){
            let response = await Ajax.get('/api/services/app/EmailEstadoParticipacion/GetEmailEstadoParticipacionForEdit?Id=' + payload.id);
             context.state.editEmailEstadoParticipacion = response.data.result.emailEstadoParticipacion
            return response.data.result.emailEstadoParticipacion as EmailEstadoParticipacion;
        },
    };
    mutations={
        setCurrentPage(state:EmailEstadoParticipacionState,page:number){
            state.currentPage=page;
        },
        setPageSize(state:EmailEstadoParticipacionState,pagesize:number){
            state.pageSize=pagesize;
        },
        edit(state:EmailEstadoParticipacionState,emailEstadoParticipacion:EmailEstadoParticipacion){
            state.editEmailEstadoParticipacion=emailEstadoParticipacion;
        }
    }
}
const emailEstadoParticipacionModule=new EmailEstadoParticipacionModule();
export default emailEstadoParticipacionModule;