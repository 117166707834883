import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Ajax from '../../../lib/ajax'
import PageResult from '@/store/entities/page-result';
import ListMutations from '../list-mutations'
import Email from '@/store/entities/OZONE/email'

interface EmailState extends ListState<Email>{
    editEmail:Email,
}
class EstadoEmailMutations extends ListMutations<Email>{

}
class EmailModule extends ListModule<EmailState,any, Email>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize:10,
        list: new Array<Email>(),
        loading:false,
        editEmail:new  Email(),
    }
    actions={
        async getAll(context:ActionContext<EmailState,any>,payload:any){
            context.state.loading=true;
            let reponse=await Ajax.get('/api/services/app/Email/GetAll',{params:payload.data});
            context.state.loading=false;
            let page=reponse.data.result as PageResult<Email>;
            context.state.totalCount=page.totalCount;
            context.state.list=page.items;
        },
        async create(context: ActionContext<EmailState, any>, payload: any) {
            await Ajax.post('/api/services/app/Email/CreateOrEdit',payload.data);
        },
        async update(context:ActionContext<EmailState,any>,payload:any){
            await Ajax.post('/api/services/app/Email/CreateOrEdit',payload.data);
        },
        async delete(context:ActionContext<EmailState,any>,payload:any){
            await Ajax.delete('/api/services/app/Email/Delete?Id='+payload.data.id);
        },
        async get(context:ActionContext<EmailState,any>,payload:any){
            let response = await Ajax.get('/api/services/app/Email/GetEmailForEdit?Id=' + payload.id);
             context.state.editEmail = response.data.result.email
             
            return response.data.result.email as Email;
        },
    };
    mutations={
        setCurrentPage(state:EmailState,page:number){
            state.currentPage=page;
        },
        setPageSize(state:EmailState,pagesize:number){
            state.pageSize=pagesize;
        },
        edit(state:EmailState,email:Email){
            state.editEmail=email;
        }
    }
}
const emailModule=new EmailModule();
export default emailModule;