import { Store, Module, ActionContext } from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Ajax from '../../../lib/ajax'
import PageResult from '@/store/entities/page-result';
import ListMutations from '../list-mutations'
import Exportacion from '@/store/entities/OZONE/exportacion'

interface ExportacionState extends ListState<Exportacion> {
    editExportacion: Exportacion,
    totalCountCampos: number,
    totalCountEans: number,
    currentPageCampos: number,
    pageSizeCampos: number,
    xml: string
}

class ExportacionModule extends ListModule<ExportacionState, any, Exportacion>{
    state = {
        totalCount: 0,
        currentPage: 1,
        pageSize: 10,
        list: new Array<Exportacion>(),
        loading: false,
        editExportacion: new Exportacion(),
        pageSizeCampos: 10,
        xml: null,
    }
    actions = {
        async getAll(context: ActionContext<ExportacionState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.post('/api/services/app/Exportacion/FindAll', payload.data);
            context.state.loading = false;
            let page = reponse.data.result as PageResult<Exportacion>;
            context.state.totalCount = page.totalCount;
            context.state.list = page.items;
        },
        async marcarEmitido(context: ActionContext<ExportacionState, any>, payload: any) {
            console.log(payload.data);
            let reponse = await Ajax.post('/api/services/app/Exportacion/MarcarEmitido', payload.data);
        },
        async downloadSEPA(context: ActionContext<ExportacionState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.post('/api/services/app/Exportacion/DowloadSEPA', payload.data);
            context.state.loading = false;
            return reponse.data.result;
        },
        async downloadReport(context: ActionContext<ExportacionState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.post('/api/services/app/Exportacion/DowloadReport', payload.data);
            context.state.loading = false;
            return reponse.data.result;
        },
        async get(context: ActionContext<ExportacionState, any>, payload: any) {
            let response = await Ajax.get('/api/services/app/Exportacion/GetExportacionForView?Id=' + payload.id);
            context.state.xml = response.data.result;
        },
    };
    mutations = {
        setCurrentPage(state: ExportacionState, page: number) {
            state.currentPage = page;
        },
        setPageSize(state: ExportacionState, pagesize: number) {
            state.pageSize = pagesize;
        },
        edit(state: ExportacionState, Exportacion: Exportacion) {
            state.editExportacion = Exportacion;
        },
    }
}
const exportacionModule = new ExportacionModule();
export default exportacionModule;