import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Ajax from '../../../lib/ajax'
import PageResult from '@/store/entities/page-result';
import ListMutations from '../list-mutations'
import Provincia from '@/store/entities/OZONE/provincia'
import Municipio from './../../entities/OZONE/municipio';

interface MunicipioState extends ListState<Municipio>{
    editMunicipio:Municipio,
}
interface MunicipioState extends ListState<Municipio>{
    editMunicipio:Municipio,
}
class MunicipioMutations extends ListMutations<Municipio>{

}
class MunicipioModule extends ListModule<MunicipioState,any, Municipio>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize:10,
        list: new Array< Municipio>(),
        loading:false,
        editMunicipio:new  Municipio(),
    }
    actions={
        async getAll(context:ActionContext<MunicipioState,any>,payload:any){
            context.state.loading = true;
            let reponse=await Ajax.get('/api/services/app/Municipio/GetAll',{params:payload.data});
            context.state.loading=false;
            let page=reponse.data.result as PageResult<Municipio>;
            context.state.totalCount=page.totalCount;
            context.state.list=page.items;
        },
        async getMunicipio(context:ActionContext<MunicipioState,any>,payload:any){
            
            context.state.loading = true;
            let reponse=await Ajax.get('/api/services/app/Municipio/GetMunicipio',{params:payload.data});
            context.state.loading=false;
            let page=reponse.data.result as PageResult<Municipio>;
            context.state.totalCount=page.totalCount;
            context.state.list=page.items;
            
        },
        async getMunicipioById(context: ActionContext<MunicipioState, any>, payload: any){
            context.state.loading = true;
            let response = await Ajax.get('/api/services/app/Municipio/GetMunicipioById',{params:payload.data})
            context.state.loading = false;
            let page = response.data.result as PageResult<Municipio>;
            context.state.totalCount = page.totalCount;
            context.state.editMunicipio = page.items[0];
        },
        async create(context: ActionContext<MunicipioState, any>, payload: any) {
            await Ajax.post('/api/services/app/Municipio/CreateOrEdit',payload.data);
        },
        async update(context:ActionContext<MunicipioState,any>,payload:any){
            await Ajax.post('/api/services/app/Municipio/CreateOrEdit',payload.data);
        },
        async delete(context:ActionContext<MunicipioState,any>,payload:any){
            await Ajax.delete('/api/services/app/Municipio/Delete?Id='+payload.data.id);
        },
       
        
    };
    mutations={
        setCurrentPage(state:MunicipioState,page:number){
            state.currentPage=page;
        },
        setPageSize(state:MunicipioState,pagesize:number){
            state.pageSize=pagesize;
        },
        edit(state:MunicipioState, municipio:Municipio){
            state.editMunicipio = municipio;
        },
        cleanCampos(state: MunicipioState){
            state.list = new Array<Municipio>();
        }
    }
}
const provinciaModule=new MunicipioModule();
export default provinciaModule;