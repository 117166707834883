import PageResult from "../page-result";
import Envio from "./envio";

export default class Devolucion {
    constructor(){
        this.pageResult = new PageResult<Envio>();
        this.pageResult.items = new Array<Envio>();
    }
    pageResult: PageResult<Envio>;
    returnedAt: string;
    notFound: boolean;
    singleResult: boolean;
}