
<template>
  <a-drawer
    title="Seguimiento Correos"
    :width="720"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    @close="onClose"
  >
    <a-form :form="form" layout="vertical" hide-required-mark>
      <a-row :gutter="16">
        <a-col :span="24">
          <a-form-item label="Código Seguimiento">
            <a-input v-model="trackCode">
              <a-icon slot="addonAfter" type="search" @click="search" />
            </a-input>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <iframe
      v-if="trackUri != null"
      :src="trackUri"
      width="100%"
      height="100"
      frameborder="0"
    >
    </iframe>
    <div
      :style="{
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e9e9e9',
        padding: '10px 16px',
        background: '#fff',
        textAlign: 'right',
        zIndex: 1,
      }"
    >
      <a-button :style="{ marginRight: '8px' }" @click="onClose">
        Cancelar
      </a-button>
    </div>
  </a-drawer>
</template>

<script>
import axios from "axios";
export default {
  props: {
    visible: {
      type: Boolean,
      description: "Visibility",
    },
    trackCode: {
      type: String,
      description: "Visibility",
    },
  },
  data() {
    return {
      form: this.$form.createForm(this),
      visible: false,
      trackUri: null,
    };
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
    search() {
      this.trackUri = 'https://www.correos.es/es/es/herramientas/localizador/envios/detalle?tracking-number=' + this.trackCode;
    },
  },
};
</script>