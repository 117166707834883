import Entity from '../entity'
import  Cliente  from '@/store/entities/OZONE/cliente';
export default class Transferencia extends Entity<number>{
    participacionId: string;
    iban: string;
    titular: string;
    importe: number;
    campanaId: number;
    estadoTransferenciaId: number;
    estadoTransferencia: string;
    motivo: string;
    fechaEnvio: string;
    fechaDevolucion: string;
    fechaCreacion: string;
    fechaEmision: string;
    exportacionSEPAId: number;
    importacionId: number;
    manual: boolean;
    observaciones: string;
    telefono:string;
    formaPago: number;
    telefonoDestinatario:string;
}