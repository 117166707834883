import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Ajax from '../../../lib/ajax'
import PageResult from '@/store/entities/page-result';
import ListMutations from '../list-mutations'
import CallCenter from '@/store/entities/OZONE/callCenter'

interface CallCenterState extends ListState<CallCenter>{
    editCallCenter: CallCenter,
}
class EstadoCallCenterMutations extends ListMutations<CallCenter>{

}
class CallCenterModule extends ListModule<CallCenterState,any, CallCenter>{
    state={
        totalCount: 0,
        currentPage: 1,
        pageSize: 10,
        list: new Array<CallCenter>(),
        loading: false,
        editCallCenter:new  CallCenter(),
    }
    actions={
        async getAll(context:ActionContext<CallCenterState,any>,payload:any){
            context.state.loading=true;
            let reponse=await Ajax.get('/api/services/app/CallCenter/GetAll',{params:payload.data});
            context.state.loading=false;
            let page=reponse.data.result as PageResult<CallCenter>;
            context.state.totalCount=page.totalCount;
            context.state.list = page.items;
        },
        async create(context: ActionContext<CallCenterState, any>, payload: any) {
            await Ajax.post('/api/services/app/CallCenter/CreateOrEdit',payload.data);
        },
        async update(context:ActionContext<CallCenterState,any>,payload:any){
            await Ajax.post('/api/services/app/CallCenter/CreateOrEdit',payload.data);
        },
        async delete(context:ActionContext<CallCenterState,any>,payload:any){
            await Ajax.delete('/api/services/app/CallCenter/Delete?Id='+payload.data.id);
        },
        async get(context:ActionContext<CallCenterState,any>,payload:any){
            let response = await Ajax.get('/api/services/app/CallCenter/GetCallCenterForEdit?Id=' + payload.id);
             context.state.editCallCenter = response.data.result.vozitel
            return response.data.result.vozitel as CallCenter;
        },
    };
    mutations={
        setCurrentPage(state: CallCenterState, page: number){
            state.currentPage = page;
        },
        setPageSize(state: CallCenterState, pagesize: number){
            state.pageSize = pagesize;
        },
        edit(state: CallCenterState, callCenter: CallCenter){
            state.editCallCenter = callCenter;
        }
    }
}
const callCenterModule=new CallCenterModule();
export default callCenterModule;