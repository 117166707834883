import Entity from '../entity'
import Campo from './campo';
export class Grupo extends Entity<number>{
    
    nombre: string;
    descripcion: number;
    camposId: Array<number>;
    Campo: Array<Campo>
}
export class GrupoTree extends Entity<number>{
    nombre: string;
    descripcion: number;
    camposId: Array<number>;
    Campo: boolean;
    padreId: number;
    elements: Array<GrupoTree>;
    selected: boolean = false;
    unique: boolean = false;    
}