import Municipio from "./municipio";
import Cliente  from "./cliente";
import Entity from '../entity'
import Provincia from "./provincia";

export default class Tienda extends Entity<number> {
    codigoCliente: string;
    tipo: string;
    direccion: string;
    municipio: Municipio|null;
    municipioId: number | null;
    provincia: Provincia|null;
    provinciaId:number | null;
    cp: string;
    telefono: string;
    idioma: string;
    estado: boolean;
    maestro: string;
    email: string;
    latitud: string;
    longuitud: string;
    cliente: Cliente|null;
    clienteId: number |null;
    nombre: string;
    nif: string;
    razonSocial: string;
    contacto: string;
}