var render = function render(){var _vm=this,_c=_vm._self._c;return _c('validation-provider',_vm._b({ref:"form",attrs:{"rules":_vm.rules,"name":_vm.name},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid, invalid, validated }){return [_c('a-form-item',{attrs:{"label":_vm.label,"rules":_vm.rules,"hasFeedback":"","validateStatus":valid && validated && _vm.successMessage
        ? 'success'
        : errors[0] && !_vm.error
        ? 'error'
        : invalid && validated
        ? 'warning'
        : null,"help":valid && validated && _vm.successMessage
        ? _vm.successMessage
        : errors[0] && !_vm.error
        ? errors[0]
        : null}},[_c('a-checkbox',_vm._g(_vm._b({attrs:{"id":_vm.cbId,"disabled":_vm.disabled},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},'a-checkbox',_vm.$attrs,false),_vm.listeners),[_vm._v(" "+_vm._s(_vm.value ? 'Si' : 'No')+" ")])],1)]}}])},'validation-provider',_vm.$attrs,false))
}
var staticRenderFns = []

export { render, staticRenderFns }