import Entity from '../entity'
import CamposOzoneACallCenter from './camposOzoneACallCenter';
import CallCenter from './callCenter';
import CallCenterEstadoParticipacion from './callCenterEstadoParticipacion';
export default class CallCenterCampana extends Entity<number>{
    vozitelId: number;
    vozitel: CallCenter;
    campanaId: number;
    campanaVozitelId: string;
    estadoVozitelId: string;
    userVozitel: string;
    passwordVozitel: string;
    camposOzoneAVozitel: Array<CamposOzoneACallCenter>
    vozitelEstadoParticipacion: Array<CallCenterEstadoParticipacion>
}