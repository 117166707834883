var render = function render(){var _vm=this,_c=_vm._self._c;return _c('validation-provider',_vm._b({ref:"form",attrs:{"rules":_vm.rules,"name":_vm.name},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid, invalid, validated }){return [_c('a-form-item',{attrs:{"label":_vm.label,"rules":_vm.rules,"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"hasFeedback":"","validateStatus":valid && validated && _vm.successMessage
        ? 'success'
        : errors[0] && !_vm.error
        ? 'error'
        : invalid && validated
        ? 'warning'
        : null,"help":valid && validated && _vm.successMessage
        ? _vm.successMessage
        : errors[0] && !_vm.error
        ? errors[0]
        : null}},[_c('a-select',_vm._g(_vm._b({staticStyle:{"width":"100%"},attrs:{"show-search":"","value":_vm.val,"options":_vm.paginated,"labelInValue":true,"placeholder":_vm.placeholder,"loading":_vm.loading,"disabled":_vm.disabled,"filter-option":_vm.filterOption}},'a-select',_vm.$attrs,false),_vm.listeners))],1)]}}])},'validation-provider',_vm.$attrs,false))
}
var staticRenderFns = []

export { render, staticRenderFns }