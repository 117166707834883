<template>
  <div>
    <a-layout class="layout-dashboard" id="layout-dashboard" :class="[navbarFixed ? 'navbar-fixed' : '', !sidebarCollapsed ? 'has-sidebar' : '', layoutClass]">
      <DashboardSidebarMuse :tenantImg="headerImage" :sidebarCollapsed="sidebarCollapsed" :sidebarColor="sidebarColor" :sidebarTheme="sidebarTheme" :menuList="menuList" @toggleSidebar="toggleSidebar"></DashboardSidebarMuse>
      <a-layout>
        <DashboardHeader :sidebarCollapsed="sidebarCollapsed" :navbarFixed="navbarFixed" :user="user" @userDropdown="logOut" @toggleSettingsDrawer="toggleSettingsDrawer" @toggleSidebar="toggleSidebar"></DashboardHeader>
        <a-layout-content>
          <router-view />
        </a-layout-content>
        <DashboardFooter></DashboardFooter>
        <div class="sidebar-overlay" @click="sidebarCollapsed = true" v-show="!sidebarCollapsed"></div>
      </a-layout>
      <DashboardSettingsDrawer :showSettingsDrawer="showSettingsDrawer" :navbarFixed="navbarFixed" :sidebarTheme="sidebarTheme" @toggleSettingsDrawer="toggleSettingsDrawer" @toggleNavbarPosition="toggleNavbarPosition" @updateSidebarTheme="updateSidebarTheme" @updateSidebarColor="updateSidebarColor"></DashboardSettingsDrawer>
    </a-layout>
  </div>
</template>

<script>
import DashboardSidebarMuse from '../components/Muse/Sidebars/DashboardSidebarMuse'
import DashboardHeader from '../components/Muse/Headers/DashboardHeader'
import DashboardFooter from '../components/Muse/Footers/DashboardFooter'
import DashboardSettingsDrawer from '../components/Muse/Sidebars/DashboardSettingsDrawer'

export default {
  components: {
    DashboardSidebarMuse,
    DashboardHeader,
    DashboardFooter,
    DashboardSettingsDrawer,
  },
  props: {
    menuList: {
      type: Array,
      default: []
    },
    user: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      // Sidebar collapsed status.
      sidebarCollapsed: false,

      // Main sidebar color.
      sidebarColor: 'primary',

      // Main sidebar theme : light, white, dark.
      sidebarTheme: 'light',

      // Header fixed status.
      navbarFixed: false,

      // Settings drawer visiblility status.
      showSettingsDrawer: false
    }
  },
  methods: {
    toggleSidebar(value) {
      this.sidebarCollapsed = value
    },
    toggleSettingsDrawer(value) {
      this.showSettingsDrawer = value
    },
    toggleNavbarPosition(value) {
      this.navbarFixed = value
    },
    updateSidebarTheme(value) {
      this.sidebarTheme = value
    },
    updateSidebarColor(value) {
      this.sidebarColor = value
    },
    logOut() {
      this.$emit('userDropdown', 'loginout')
    }
  },
  computed: {
    // Sets layout's element's class based on route's meta data.
    layoutClass() {
      return this.$route.meta.layoutClass
    },
    headerImage(){
      if(this.$store.state.session.tenant == null){
      return "img/Group-6.png";
    }
    else if(this.$store.state.session.tenant && this.$store.state.session.tenant.name == "People"){
      return "img/Group-6.png";
    }
    else{
        return "img/PG.png"
    }
  }
  }
}
</script>
