import axios from 'axios'
import appconst from './appconst'
import Vue from 'vue'
import { BootstrapVue } from 'bootstrap-vue';
Vue.use(BootstrapVue);
const ajax = axios.create({
    baseURL: appconst.remoteServiceBaseUrl,
    timeout: 120000
});
ajax.interceptors.request.use(function (config) {
    if(!!window.abp.auth.getToken()){
        config.headers.common["Authorization"]="Bearer "+window.abp.auth.getToken();
    }
    config.headers.common[".AspNetCore.Culture"]=window.abp.utils.getCookieValue("Abp.Localization.CultureName");
    config.headers.common["Abp.TenantId"]=window.abp.multiTenancy.getTenantIdCookie();
    return config;
  }, function (error) {
    
    return Promise.reject(error);
});
let vm = new Vue({});
ajax.interceptors.response.use((respon)=>{    
    return respon
}, (error) => {
    if (!!error.response && !!error.response.data.error && !!error.response.data.error.message && error.response.data.error.details) {
      
        vm.$bvModal.msgBoxOk(error.response.data.error.details, {
          title: error.response.data.error.message,
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true
        })
    } else if (!!error.response && !!error.response.data.error && !!error.response.data.error.message) {
        vm.$bvModal.msgBoxOk(error.response.data.error.message, {
          title: window.abp.localization.localize(error.response.data.error.details || 'Error',appconst.localization.defaultLocalizationSourceName),
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true
        })
    } else if (!error.response) {
         vm.$bvModal.msgBoxOk(window.abp.localization.localize('UnknownError',appconst.localization.defaultLocalizationSourceName), {
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true
        })
    }
    //return Promise.reject(error);
    return Promise.resolve(error);
})
export default ajax;