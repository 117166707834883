import Entity from '../entity'
import { EstadoParticipacion } from './estadoParticipacion';
import SubEstadoParticipacion from './subEstadoParticipacion';
import Campo from './campo';
import MetodoEnvio from "./metodoEnvio";
import Campana from "./campana";
import ServicioMensajeria from "./servicioMensajeria";

export default class ProgramacionEnviosCampana extends Entity<number> {
    campanaId: number;
    fechaInicio: string | null;
    fechaFin: string | null;
    ordenCompra: string;
    pausa: boolean;
    estadoParticipacionId: number | null;
    subEstadoParticipacionId: number | null;
    metodoEnvioId: number | null;
    servicioMensajeriaId: number | null;
    metodoEnvio: MetodoEnvio;
    campana: Campana;
    estadoParticipacion: EstadoParticipacion;
    subEstadoParticipacion: SubEstadoParticipacion;
    servicioMensajeria: ServicioMensajeria;
    campo : Campo;
    diasMaximosEnvio : number;
}
