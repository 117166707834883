import {Store,Module,ActionContext} from 'vuex'
import Ajax from '../../../lib/ajax'
import ListMutations from '../list-mutations'
import ReglasTicket from '@/store/entities/OZONE/reglasTicket';

interface ReglasTicketState  {
    list: Array<ReglasTicket>,
    loading:boolean
}
class ReglasTicketMutations extends ListMutations<ReglasTicket>{

}
class ReglasTicketModule implements Module<ReglasTicketState, ReglasTicket>{
    namespaced=true;
    state = {
        list: new Array<ReglasTicket>(),
        loading:false
    }
    actions={
        // async getAll(context:ActionContext<ReglasTicketState,any>,payload:any){
        //     context.state.loading=true;
        //     let response=await Ajax.get('/api/services/app/Campana/GetAllReglasTicket');
        //     context.state.loading=false;
        //     context.state.list = response.data.result;
        //     return response.data.result
        // }
       
        // async get(context:ActionContext<CampanaTipoState,any>,payload:any){
        //     let reponse=await Ajax.get('/api/services/app/CampanaTipo/GetCampanaTipoForEdit?Id='+payload.id);
        //     return reponse.data.result as CampanaTipo;
        // },
    };
}
const reglasTicketModule=new ReglasTicketModule();
export default reglasTicketModule;