import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Ajax from '../../../lib/ajax'
import PageResult from '@/store/entities/page-result';
import ListMutations from '../list-mutations'
import EstadoCampana from './../../entities/OZONE/estadoCampana';

interface EstadoCampanaState  {
    editEstadoCampana: EstadoCampana,
    list: Array<EstadoCampana>,
    loading:boolean
}
class EstadoCampanaMutations extends ListMutations<EstadoCampana>{

}
class EstadoCampanaModule implements Module<EstadoCampanaState, EstadoCampana>{
    namespaced=true;
    state = {
        list: new Array< EstadoCampana>(),
        loading:false,
        editEstadoCampana:new  EstadoCampana(),
    }
    actions={
        async getAll(context:ActionContext<EstadoCampanaState,any>,payload:any){
            context.state.loading=true;
            let response=await Ajax.get('/api/services/app/Campana/GetAllEstados');
            context.state.loading=false;
            context.state.list=response.data.result;
        }
       
        // async get(context:ActionContext<EstadoCampanaState,any>,payload:any){
        //     let reponse=await Ajax.get('/api/services/app/EstadoCampana/GetEstadoCampanaForEdit?Id='+payload.id);
        //     return reponse.data.result as EstadoCampana;
        // },
    };
}
const estadoCampanaModule=new EstadoCampanaModule();
export default estadoCampanaModule;