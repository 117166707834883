import Entity from '../entity'
import CallCenterCampana from './callCenterCampana';
import ValidateImagenCampana from './validateImagenCampana';
export default class Campana extends Entity<number>{
    concurrencyStamp: string
    nombre: string;
    fechaInicio: string;
    fechaFin: string;
    camposId: Array<number>;
    clienteId: number;
    clienteNombre: string;
    marcaId: number;
    emailId: number;
    emailDefaultFromAddress: string;
    campanaTipoId: number;
    campanaTipoNombre: string;
    estadoCampanaId: number;
    estadoCampanaNombre: string;
    axapta: string;
    pais: string;
    presupuesto: number;
    participacionesEstimada: number;
    estadoParticipacionTree: string;
    estadoParticipacionTreeUnselected: string;
    grupoTree: string;
    grupoTreeUnselected: string;
    vozitelCampana: CallCenterCampana;
    validateImagenCampana: ValidateImagenCampana;

    puedeHacerseSorteo:boolean;
    totalSorteosRealizados:boolean
    fechaGuardadoDatos: string;
    emailManager: string;

}