import { Store, Module, ActionContext } from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Ajax from '../../../lib/ajax'
import PageResult from '@/store/entities/page-result';
import ListMutations from '../list-mutations'
import FicheroEnvio from '@/store/entities/OZONE/ficheroEnvio'

interface FicheroEnvioState extends ListState<FicheroEnvio> {
    editFicheroEnvio: FicheroEnvio,
    totalCountCampos: number,
    totalCountEans: number,
    currentPageCampos: number,
    pageSizeCampos: number,
    xml: string
}

class FicheroEnvioModule extends ListModule<FicheroEnvioState, any, FicheroEnvio>{
    state = {
        totalCount: 0,
        currentPage: 1,
        pageSize: 10,
        list: new Array<FicheroEnvio>(),
        loading: false,
        editFicheroEnvio: new FicheroEnvio(),
        pageSizeCampos: 10,
        xml: null,
    }
    actions = {
        async getAll(context: ActionContext<FicheroEnvioState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.post('/api/services/app/FicheroEnvio/FindAll', payload.data);
            context.state.loading = false;
            let page = reponse.data.result as PageResult<FicheroEnvio>;
            context.state.totalCount = page.totalCount;
            context.state.list = page.items;
            
        },
        async marcarEmitido(context: ActionContext<FicheroEnvioState, any>, payload: any) {
            console.log(payload.data);
            context.state.loading = true;
            let reponse = await Ajax.post('/api/services/app/FicheroEnvio/MarcarEmitido', payload.data, {timeout: 100000});
            context.state.loading = false;
        },
        async eliminarFichero(context: ActionContext<FicheroEnvioState, any>, payload: any) {
            console.log(payload.data);
            context.state.loading = true;
            let reponse = await Ajax.post('/api/services/app/FicheroEnvio/RevertirFichero', payload.data, {timeout: 100000});
            context.state.loading = false;
        },
        async downloadReport(context: ActionContext<FicheroEnvioState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.post('/api/services/app/FicheroEnvio/DowloadReport', payload.data);
            context.state.loading = false;
            return reponse.data.result;
        },

        async dowloadEnvialiaTag(context: ActionContext<FicheroEnvioState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.post('/api/services/app/FicheroEnvio/DownloadEnvialiaTag', payload.data);
            context.state.loading = false;
            return reponse.data.result;

        },
        async dowloadGECOTag(context: ActionContext<FicheroEnvioState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.post('/api/services/app/FicheroEnvio/DownloadGECOTag', payload.data);
            context.state.loading = false;
            return reponse.data.result;

        },

        async get(context: ActionContext<FicheroEnvioState, any>, payload: any) {
            let response = await Ajax.get('/api/services/app/FicheroEnvio/GetExportacionForView?Id=' + payload.id);
            context.state.xml = response.data.result;
        },
    };
    mutations = {
        setCurrentPage(state: FicheroEnvioState, page: number) {
            state.currentPage = page;
        },
        setPageSize(state: FicheroEnvioState, pagesize: number) {
            state.pageSize = pagesize;
        },
        edit(state: FicheroEnvioState, Exportacion: FicheroEnvio) {
            
            state.editFicheroEnvio = Exportacion;
        },
    }
}
const ficheroEnvioModule = new FicheroEnvioModule();
export default ficheroEnvioModule;