import Entity from '../entity'
import SubEstadoParticipacion from './subEstadoParticipacion';
export class EstadoParticipacion extends Entity<number>{
    nombre: string;
    estadoFinal: boolean;
    estadoInicial: boolean;
    subEstadoParticipaciones: Array<SubEstadoParticipacion>
}

export class EstadoParticipacionTree extends Entity<number>{
    nombre: string;
    subEstado: boolean;
    padreId: number;
    elements: Array<EstadoParticipacionTree>;
}