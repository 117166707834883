import { Store, Module, ActionContext } from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Ajax from '../../../lib/ajax'
import PageResult from '@/store/entities/page-result';
import Envio from '@/store/entities/OZONE/envio'
import TransferenciaDash from '@/store/entities/OZONE/dashboardsTransferencias'
import EnvioGroup from '@/store/entities/OZONE/envioGroup';
import StockEnvios from '@/store/entities/OZONE/stockEnvio';
import Devolucion from '@/store/entities/OZONE/devolucion';
import GenerarExportacion from '@/store/entities/OZONE/generarExportacion';
import DisenoExportacion from '../../entities/OZONE/disenoExportacion';

interface EnvioState extends ListState<Envio> {
    participacionesId: number[];
    dashboard: TransferenciaDash,
    totalCountCampos: number,
    totalImporte: number,
    currentPageCampos: number,
    // pageSizeCampos: number,
    totalCountGroup: number,
    currentPageGroup: number,
    download: string,
    listGroup: Array<EnvioGroup>,
    loadingGroup: boolean,
    stockEnvios: Array<StockEnvios>
    totalstockEnvios: number,
    disenos: Array<DisenoExportacion>,
    generarExportacion: GenerarExportacion,
    devoluciones: Devolucion,
    editEnvio: Envio,
}

class EnvioModule extends ListModule<EnvioState, any, Envio>{
    state = {
        totalCount: 0,
        totalCountGroup: 0,
        currentPage: 1,
        currentPageGroup: 1,
        pageSize: 10,
        list: new Array<Envio>(),
        listGroup: new Array<EnvioGroup>(),
        loading: false,
        loadingGroup: false,
        devoluciones: new Devolucion(),
        disenos: new Array<DisenoExportacion>(),
        generarExportacion: new GenerarExportacion(),
        dashboard: new TransferenciaDash(),
        pageSizeCampos: 10,
        download: null,
        totalImporte: 0,
        stockEnvios: new Array<StockEnvios>(),
    }
    actions = {
        async getAll(context: ActionContext<EnvioState, any>, payload: any) {
            
            context.state.loading = true;
            let reponse = await Ajax.post('/api/services/app/Envio/FindAll', payload.data);
            context.state.loading = false;
            let page = reponse.data.result as PageResult<Envio>;
            context.state.totalCount = page.totalCount;
            context.state.list = page.items;
        },
        async getStock(context: ActionContext<EnvioState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.post('/api/services/app/Envio/Stock',payload.data, {timeout: 100000000});
            context.state.loading = false;

            let page = reponse.data.result as PageResult<StockEnvios>;
            context.state.totalstockEnvios = page.totalCount;
            context.state.stockEnvios = page.items;
        },        
        async generarFichero(context: ActionContext<EnvioState, any>, payload: any) {            
            context.state.loading = true;
            let reponse = await Ajax.post('/api/services/app/Envio/GenerarFichero',payload.data, {timeout: 100000000});
            context.state.loading = false;
            context.state.download = reponse.data.result;
        },
        async getAllGroup(context: ActionContext<EnvioState, any>, payload: any) {
            context.state.loadingGroup = true;
            let reponse = await Ajax.post('/api/services/app/Envio/FindAllGroup', payload.data);
            context.state.loadingGroup = false;
            let page = reponse.data.result as PageResult<EnvioGroup>;
            context.state.totalCountGroup = page.totalCount;
            context.state.listGroup = page.items;
        },
        async getDevoluciones(context: ActionContext<EnvioState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.post('/api/services/app/Envio/FindDevoluciones', payload.data, { timeout: 1000000 });
            context.state.loading = false;
            let page = reponse.data.result as Devolucion;
            context.state.totalCount = page.pageResult.totalCount;
            context.state.devoluciones = page;
        },
        async importar(context: ActionContext<EnvioState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.post('/api/services/app/Envio/ImportarEnvio', payload.data);
            context.state.loading = false;
        },
        async devolucion(context: ActionContext<EnvioState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.post('/api/services/app/Envio/Devolucion', payload.data);
            context.state.loading = false;
        },
        async anularDevolucion(context: ActionContext<EnvioState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.post('/api/services/app/Envio/AnularDevolucion', payload.data);
            context.state.loading = false;
        },
        async getDisenos(context: ActionContext<EnvioState, any>, payload: any) {

            context.state.loading = true;
            let reponse = await Ajax.get('/api/services/app/Envio/GetDisenos', {params: payload.data});
            context.state.loading = false;
            let disenos = reponse.data.result as Array<DisenoExportacion>;
            context.state.disenos = disenos;
        },
        async insertarDiseno(context: ActionContext<EnvioState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.post('/api/services/app/Envio/InsertOrUpdateDiseno', payload.data, {timeout:1000000});
            context.state.loading = false;
        },
        async generarExportacion(context: ActionContext<EnvioState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.post('/api/services/app/Envio/GenerarExportacion', payload.data, {timeout:1000000});
            context.state.loading = false;
            return reponse.data.result;
        },
        async getHistorial(context: ActionContext<EnvioState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/services/app/Envio/GetEnvioHistory', { params: payload.data });
            context.state.loading = false;
            return reponse.data.result.estados;
        },
        async reenviar(context: ActionContext<EnvioState, any>, payload: any){
            context.state.loading = true;
            let response = await Ajax.post('/api/services/app/Envio/Reenviar', payload.data);
            context.state.loading = false;
            return response.data.result;
        },
        async updateEnvio(context: ActionContext<EnvioState, any>, payload: any){
            context.state.loading = true;
            let response = await Ajax.put('/api/services/app/Envio/UpdateShipment', payload.data);
            context.state.loading = false;
        },
        async marcarEnvioUrgente(context: ActionContext<EnvioState, any>, payload: any){
            context.state.loading = true;
            let response = await Ajax.post('/api/services/app/Envio/MarcarEnvioUrgente', payload.data);
            context.state.loading = false;
            return response.data.result;
        },
    };
    mutations = {
        setCurrentPage(state: EnvioState, page: number) {
            state.currentPage = page;
        },
        setPageSize(state: EnvioState, pagesize: number) {
            state.pageSize = pagesize;
        },
        clearData(state: EnvioState) {
            state.devoluciones = new Devolucion;
            state.list = [];
        },
        setCurrentPageGroup(state: EnvioState, page: number) {
            state.currentPageGroup = page;
        },
        clearDataGroup(state: EnvioState) {
            state.listGroup = [];
            state.currentPage = 1;
            state.pageSize = 10;
        },
        edit(state: EnvioState, envio: Envio) {
            state.editEnvio = envio;
        },
        addNewEnvio(state: EnvioState, envio: Envio){
            state.list.push(envio);
        }
    }
}
const envioModule = new EnvioModule();
export default envioModule;