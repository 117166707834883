import Entity from '../entity'

export default class Deduplicacion extends Entity<number>{
    origen: string;
    fichero: string;
    registros: number;
    duplicados: number;
    completada: boolean;
    observaciones: string;

}