import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Ajax from '../../../lib/ajax'
import PageResult from '@/store/entities/page-result';
import ListMutations from '../list-mutations'
import SubEstadoParticipacion from '@/store/entities/OZONE/subEstadoParticipacion'

interface SubEstadoParticipacionState extends ListState<SubEstadoParticipacion>{
    editSubEstadoParticipacion:SubEstadoParticipacion,
}
class SubEstadoParticipacionMutations extends ListMutations<SubEstadoParticipacion>{

}
class SubEstadoParticipacionModule extends ListModule<SubEstadoParticipacionState,any, SubEstadoParticipacion>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize:10,
        list: new Array< SubEstadoParticipacion>(),
        loading:false,
        editSubEstadoParticipacion:new  SubEstadoParticipacion(),
    }
    actions={
        async findAll(context:ActionContext<SubEstadoParticipacionState,any>,payload:any){
            
            context.state.loading = true;
            let reponse = await Ajax.post('/api/services/app/SubEstadoParticipacion/FindAll', payload.data  );
            context.state.loading=false;
            let page=reponse.data.result as PageResult<SubEstadoParticipacion>;
            context.state.totalCount=page.totalCount;
            context.state.list=page.items;
        },
        async getAll(context:ActionContext<SubEstadoParticipacionState,any>,payload:any){
            context.state.loading = true;
            let reponse = await Ajax.get('/api/services/app/SubEstadoParticipacion/GetAll', {params: payload.data } );
            context.state.loading=false;
            let page=reponse.data.result as PageResult<SubEstadoParticipacion>;
            context.state.totalCount=page.totalCount;
            context.state.list=page.items;
        },
        async create(context: ActionContext<SubEstadoParticipacionState, any>, payload: any) {
            await Ajax.post('/api/services/app/SubEstadoParticipacion/CreateOrEdit',payload.data);
        },
        async update(context:ActionContext<SubEstadoParticipacionState,any>,payload:any){
            await Ajax.post('/api/services/app/SubEstadoParticipacion/CreateOrEdit',payload.data);
        },
        async delete(context:ActionContext<SubEstadoParticipacionState,any>,payload:any){
            await Ajax.delete('/api/services/app/SubEstadoParticipacion/Delete?Id='+payload.data.id);
        },
        async get(context:ActionContext<SubEstadoParticipacionState,any>,payload:any){
            let reponse=await Ajax.get('/api/services/app/SubEstadoParticipacion/GetSubEstadoParticipacionForEdit?Id='+payload.id);
            return reponse.data.result as SubEstadoParticipacion;
        },
    };
    mutations={
        setCurrentPage(state:SubEstadoParticipacionState,page:number){
            state.currentPage=page;
        },
        setPageSize(state:SubEstadoParticipacionState,pagesize:number){
            state.pageSize=pagesize;
        },
        edit(state:SubEstadoParticipacionState,subEstadoParticipacion:SubEstadoParticipacion){
            state.editSubEstadoParticipacion=subEstadoParticipacion;
        }
    }
}
const subEstadoParticipacionModule=new SubEstadoParticipacionModule();
export default subEstadoParticipacionModule;