import Entity from '../entity'
export default class Ean extends Entity<number>{
    titulo: string;
    cod_ean: string;
    marca: string;
    categoria: string;
    Importe_Fijo: string;
    Importe_Porcentual: string;
    campanaId: number;

}