import Entity from '../entity'
import { EstadoParticipacion } from './estadoParticipacion';
import SubEstadoParticipacion from './subEstadoParticipacion';
export default class EmailEstadoParticipacion extends Entity<number>{
    nombre: string;
    alias: string;
    asunto: string;
    contenido: string;
    estadoParticipacion: EstadoParticipacion;
    estadoParticipacionId: number;
    subEstadoParticipacion: SubEstadoParticipacion;
    subEstadoParticipacionId: number;
    campanaId: number;
    emailMasivo: string;
    solicitudConsumidor: boolean;
    solicitudConsumidorOpciones: string;
}