<template>
  <validation-provider
    :rules="rules"
    :name="name"
    v-bind="$attrs"
    v-slot="{ errors, valid, invalid, validated }"
    ref="form"
  >
    <a-form-item
      :label="label"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
      hasFeedback
      :validateStatus="
        valid && validated && successMessage
          ? 'success'
          : errors[0] && !error
          ? 'error'
          : invalid && validated
          ? 'warning'
          : null
      "
      :help="
        valid && validated && successMessage
          ? successMessage
          : errors[0] && !error
          ? errors[0]
          : null
      "
    >
      <a-select
        show-search
        :value="val"
        :options="paginated"
        :labelInValue="true"
        :placeholder="placeholder"
        :loading="loading"
        :disabled="disabled"
        :filter-option="filterOption"
        style="width:100%"
        v-bind="$attrs"
        v-on="listeners"
      />
    </a-form-item>
  </validation-provider>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
  components: {
    Multiselect,
  },

  name: "base-filtrable-dropdown",
  props: {
    value: {
      type: [String, Number, Object],
      description: "Input value"
    },
    options: {
      type: [Array],
      description: "Vee validate validation rules",
      default: [],
    },
    labelCol: {
      type: Object,
      default: () => ({})
    },
    wrapperCol: {
      type: Object,
      default: () => ({})
    },
    trackby: {
      type: [String, Number, Object],
      description: "Vee validate validation rules",
    },
    trackfor: {
      type: [String, Number, Object],
      description: "Vee validate validation rules",
    },
    trackforPrefix: {
      type: [String,Number],
      description: "Vee validate validation rules",
    },
    rules: {
      type: [String, Array, Object],
      description: "Vee validate validation rules",
      default: "",
    },
    label: {
      type: String,
      description: "Input label (text before input)",
    },
    name: {
      type: String,
      description: "Input name (used for validation)",
      default: "",
    },
    placeholder: {
      type: String,
      description: "placeholder",
      default: "Select Option",
    },
    message: {
      type: String,
      description: "message",
      default: "Select Option",
    },
    successMessage: {
      type: String,
      description: "Input success message",
      default: "",
    },
    error: {
      type: String,
      description: "Input error (below input)",
    },
    labelClasses: {
      type: String,
      description: "Input label css classes",
      default: "form-control-label",
    },
    selectLabel: {
      type: String,
      description: "Input label (text before input)",
    },
    showlabels: {
      type: Boolean,
      description: "Input label (text before input)",
    },
    showPagination: {
      type: Boolean,
      description: "showPagination",
      default: true,
    },
    allowempty: {
      type: Boolean,
      description: "Input label (text before input)",
    },
    loading: {
      type: Boolean,
      description: "loading",
    },
    total: {
      type: Number,
    },
    maxResultCount: {
      type: Number,
    },
    skipCount: {
      type: Number,
    },
    disabled: {
      type: Boolean,
      description: 'disabled'
    },
    openDirection: {
      type: String,
      default: 'bottom'
    }
  },
  data() {
    return {
      offset: this.skipCount,
      limit: this.maxResultCount,
      focused: false,
      //valor: ''
    };
  },
  computed: {
    filtered() {
      return this.options; //.filter((x) => x.includes(this.search))
    },
    listeners() {
      return {
        ...this.$listeners,
        onSearch: this.search_Change,
        blur: this.onBlur,
        change: this.valChange
      };
    },
    slotData() {
      return {
        focused: this.focused,
        error: this.error,
        ...this.listeners,
      };
    },
    val: {
      get: function () {
        if (this.value) {
          return {
            label: this.trackforPrefix && this.value[this.trackforPrefix] != null ? this.value[this.trackforPrefix] + "-" + this.value[this.trackfor] : this.value[this.trackfor],
            value: this.value[this.trackby],
          };
        }
        return {
            label: 'Seleccione',
            value: -1,
          };
      },
      set: function (newValue) {
        this.$emit("input", newValue);
      },
    },
    paginated() {
      let options = this.options;
      let values = options.map((o) => {
        return {
          label: this.trackforPrefix && o[this.trackforPrefix] != null ? o[this.trackforPrefix].toString() + "-" + o[this.trackfor].toString() : o[this.trackfor].toString(),
          value: o[this.trackby].toString(),
          key: o[this.trackby].toString(),
        };
      });
      return values;
    },
    hasNextPage() {
      const nextOffset = this.offset + this.limit;
      //return Boolean(this.options.slice(nextOffset, this.limit + nextOffset).length)
      return this.total > nextOffset;
    },
    hasPrevPage() {
      const prevOffset = this.offset - this.limit;
      //return Boolean(this.options.slice(prevOffset, this.limit + prevOffset).length)
      return prevOffset >= 0;
    },
    hasPagination() {
      return this.options.length < this.total;
    },
    valor() {
      return this.value[this.trackfor];
    },
  },
  watch: {
    skipCount() {
      this.offset = skipCount;
    },
  },
  methods: {
    onBlur(e) {
      this.focused = false;
      this.$emit("blur", e);
    },
    handleFocus(e){
      this.focused = true;
      this.$emit("focus", e);
    },
    nextPage() {
      this.offset += this.limit;
      this.$emit("nextPage", this.offset);
    },
    prevPage() {
      this.offset -= this.limit;
      this.$emit("nextPage", this.offset);
    },
    search_Change(text) {
      this.offset = this.skipCount;
      this.limit = this.maxResultCount;
      this.$emit("searchChange", text);
    },
    valChange(newValue) {
      var opt = this.options.filter((obj) => {
        return obj[this.trackby] == newValue.key;
      });
      this.val = opt[0];
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    }
  },
};
</script>
<style>
.pagination {
  display: flex;
  margin: 0.25rem 0.25rem 0;
}
.pagination button {
  flex-grow: 1;
}
.pagination button:hover {
  cursor: pointer;
}
.multiselect .multiselect__tags {
  min-height: 38px;
  height: 38px;
  display: block;
  padding: 5px 38px 0 8px;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  background: #fff;
  font-size: 14px;
}
.multiselect .multiselect__placeholder {
  color: #495057;
  display: inline-block;
  margin-bottom: 10px;
  padding-top: 2px;
  font-size: 1rem;
  font-weight: 400;
}
/* .multiselect__content-wrapper{
    position: relative !important;
} */
</style>
