import Entity from '../entity'
export default class Exportacion extends Entity<number>{
    transferencias: number;
    totalFichero: number;
    cuentaEmisora: string;
    fichero: string;
    informe: string;
    iban: string;
    fechaCreacion: string;
    fechaExportacion: string;
    pendiente: boolean;
}