import AppConsts from './appconst'
import Util from './util'
class SignalRAspNetCoreHelper{
    initSignalR(){
        var encryptedAuthToken = Util.abp.utils.getCookieValue(AppConsts.authorization.encrptedAuthTokenName);
        Util.abp.signalr = {
            autoConnect: true,
            connect: undefined,
            hubs: undefined,
            qs: AppConsts.authorization.encrptedAuthTokenName + "=" + encodeURIComponent(encryptedAuthToken),
            remoteServiceBaseUrl: AppConsts.remoteServiceBaseUrl,
            url: '/signalr-myChatHub'
        };
         Util.loadScript(AppConsts.appBaseUrl + '/dist/abp.signalr-client.js',);
        // var hitchHub;
        //  
    //     Util.abp.signalr.startConnection('/signalr-myChatHub', function (connection) {
            
    //     hitchHub = connection; // Save a reference to the hub

    //     connection.on('getMessage', function (message) { // Register for incoming messages
    //         console.log('received message: ' + message);
    //     });
    // }).then(function (connection) {
    //     abp.log.debug('Connected to myChatHub server!');
    //     abp.event.trigger('myChatHub.connected');
    // });

    // abp.event.on('myChatHub.connected', function() { // Register for connect event
    //     hitchHub.invoke('sendMessage', "Hi everybody, I'm connected to the chat!"); // Send a message to the server
    // });
    }
}
export default new SignalRAspNetCoreHelper();