import { Store, Module, ActionContext } from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Ajax from '../../../lib/ajax'
import PageResult from '@/store/entities/page-result';
import ListMutations from '../list-mutations'
import Regalo from '@/store/entities/OZONE/regalo'
import ean from '../../entities/OZONE/ean';

interface RegaloState extends ListState<Regalo> {
    editRegalo: Regalo,
}
class RegaloMutations extends ListMutations<Regalo>{

}
class RegaloModule extends ListModule<RegaloState, any, Regalo>{
    state = {
        totalCount: 0,
        currentPage: 1,
        pageSize: 10,
        list: new Array<Regalo>(),
        loading: false,
        editRegalo: new Regalo(),

    }
    actions = {
        // async getAll(context: ActionContext<EanState, any>, payload: any) {
        //     context.state.loading = true;
        //     let reponse = await Ajax.get('/api/services/app/Ean/GetAll', payload.data.id);
        //     context.state.loading = false;
        //     let page = reponse.data.result as PageResult<Ean>;
        //     context.state.totalCount = page.totalCount;
        //     context.state.list = page.items;
          
        // },
        async create(context: ActionContext<RegaloState, any>, payload: any) {
            await Ajax.post('/api/services/app/Regalo/CreateOrEdit', payload.data);
        },
        async update(context: ActionContext<RegaloState, any>, payload: any) {
            await Ajax.post('/api/services/app/Regalo/CreateOrEdit', payload.data);
        },
        async delete(context: ActionContext<RegaloState, any>, payload: any) {
            await Ajax.delete('/api/services/app/Regalo/Delete?Id=' + payload.data.id);
        },
        async deleteAll(context: ActionContext<RegaloState, any>, payload: any){
            await Ajax.delete('/api/services/app/Regalo/DeleteAllGifts?campanaId='+payload.data);
        },
        async leerExcell(context: ActionContext<RegaloState, any>, payload: any) {
           
            await Ajax.post('/api/services/app/Regalo/InsertarRegalo', payload.data);
           
        },
        async comprobaRepetidos(context: ActionContext<RegaloState, any>, payload: any) {
           
            await Ajax.post('/api/services/app/Regalo/ComprobarRepetidos', payload.data);
           
        },
        // async get(context: ActionContext<EanState, any>, payload: any) {
        //     let response = await Ajax.get('/api/services/app/Ean/GetEanForEdit?Id=' + payload.id);
        //     context.state.editEan = response.data.result.ean
        //     return response.data.result.ean as Ean;
        // },
        // async getAllEans(context: ActionContext<EanState, any>, payload: any) {
        //     // let response = await Ajax.get('/api/services/app/Campana/GetAllEans?CampanaId=' + payload.id);
        //     let response = await Ajax.get('/api/services/app/Campana/GetAllEans',{params:payload.data});
        //     context.state.editEan = response.data.result.ean
        //     return response.data.result.ean as Ean;
        // },
    };
    mutations = {
        setCurrentPage(state: RegaloState, page: number) {
            state.currentPage = page;
        },
        setPageSize(state: RegaloState, pagesize: number) {
            state.pageSize = pagesize;
        },
        edit(state: RegaloState, regalo: Regalo) {
            
            state.editRegalo = regalo;
        }
    }
}
const regaloModule = new RegaloModule();
export default regaloModule;