import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Ajax from '../../../lib/ajax'
import PageResult from '@/store/entities/page-result';
import CuentaEmisora from '@/store/entities/OZONE/cuentaEmisora'



interface CuentaEmisoraState  extends ListState<CuentaEmisora>{

    editCuentaEmisora : CuentaEmisora
   
   
}
class CuentaEmisoraModule extends ListModule<CuentaEmisoraState,any, CuentaEmisora>{
    state={
        totalCount :0,
        currentPage:1,
        pageSize:10,
        list: new Array<CuentaEmisora>(),
        loading:false,
        editCuentaEmisora :new CuentaEmisora(),
      
    }
    actions={
        async getAll(context:ActionContext<CuentaEmisoraState,any>,payload:any){
            context.state.loading = true;
            let reponse=await Ajax.get('/api/services/app/CuentaEmisora/GetAll',{params:payload.data});
            context.state.loading=false;
            let page=reponse.data.result as PageResult<CuentaEmisora>;
            context.state.totalCount=page.totalCount;
            context.state.list=page.items;
            
        },
        async create(context: ActionContext<CuentaEmisoraState, any>, payload: any) {
            
            await Ajax.post('/api/services/app/CuentaEmisora/CreateOrEdit',payload.data);
            

        },
        async update(context:ActionContext<CuentaEmisoraState,any>,payload:any){
            await Ajax.post('/api/services/app/CuentaEmisora/CreateOrEdit',payload.data);
        },
        async delete(context:ActionContext<CuentaEmisoraState,any>,payload:any){
            await Ajax.delete('/api/services/app/CuentaEmisora/Delete?Id='+payload.data.id);
            
        }
    }
    mutations={
        setCurrentPage(state:CuentaEmisoraState,page:number){
            state.currentPage=page;
        },
        
        setPageSize(state:CuentaEmisoraState,pagesize:number){
            
            state.pageSize=pagesize;
        },
        edit(state:CuentaEmisoraState,cuentaEmisora:CuentaEmisora){
            
            state.editCuentaEmisora=cuentaEmisora;
           
        },
        
    }
    
}
const cuentaModule=new CuentaEmisoraModule();
export default cuentaModule;