<template>
  <validation-provider
    :rules="rules"
    :name="name"
    v-bind="$attrs"
    v-slot="{ errors, valid, invalid, validated }"
    ref="form"
  >
    <a-form-item
      :label="label"
      :rules="rules"
      hasFeedback
      :validateStatus="
        valid && validated && successMessage
          ? 'success'
          : errors[0] && !error
          ? 'error'
          : invalid && validated
          ? 'warning'
          : null
      "
      :help="
        valid && validated && successMessage
          ? successMessage
          : errors[0] && !error
          ? errors[0]
          : null
      "
    >
      <a-checkbox
        :id="cbId"
        v-model="model"
        v-on="listeners"
        v-bind="$attrs"
        :disabled="disabled"
      >
        {{ value ? 'Si' : 'No' }}
      </a-checkbox>
    </a-form-item>
  </validation-provider>
</template>
<script>
export default {
  name: "base-checkbox",
  props: {
    checked: {
      type: [Array, Boolean],
      description: "Whether checkbox is checked",
    },
    disabled: {
      type: Boolean,
      description: "Whether checkbox is disabled",
    },
    inline: {
      type: Boolean,
      description: "Whether checkbox is inline",
    },
    inputClasses: {
      type: [String, Object, Array],
      description: "Checkbox input classes",
    },
    type: {
      type: String,
      description: "Checkbox type (e.g info, danger etc)",
    },
    label: {
      type: String,
      description: "Label",
    },
    value: {
      type: [Boolean],
      description: "Input value",
    },
    rules: {
      type: [String, Array, Object],
      description: 'Vee validate validation rules',
      default: ''
    },
    name: {
      type: String,
      description: 'Input name (used for validation)',
      default: ''
    },
    successMessage: {
      type: String,
      description: 'Input success message',
      default: ''
    },
  },
  data() {
    return {
      cbId: "",
      touched: false,
    };
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(check) {
        this.$emit("input", check);
      },
    },
    inlineClass() {
      if (this.inline) {
        return `form-check-inline`;
      }
    },
    listeners() {
      return {
        ...this.$listeners,
        //input: this.updateValue,
        //focus: this.onFocus,
        //blur: this.onBlur
      }
    },
  },
  created() {
    this.cbId = Math.random().toString(16).slice(2);
  },
};
</script>
