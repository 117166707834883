import Ajax from '../../../lib/ajax'
import Charlie from '@/store/entities/OZONE/charlie'
import ListState from '../list-state';
import ListModule from '../list-module';
import {ActionContext} from 'vuex'

interface CharlieState extends ListState<Charlie>{

}

class CharlieModule extends ListModule<CharlieState, any, Charlie>{ 
  actions={
    async postProcesaDatosCharlie(context: ActionContext<CharlieState, any>, payload: any) {
      Ajax.post('https://charlieapi.pdm.es/api/Charlie/ProcesaDatos', payload.data, {timeout: 1000000});
      //Ajax.post('https://localhost:44305/api/Charlie/ProcesaDatos', payload.data, {timeout: 1000000});
    },
    async generarFicheroCharlie(context: ActionContext<CharlieState, any>, payload: any) {
      Ajax.post('https://charlieapi.pdm.es/api/Charlie/GeneraSalidas', payload.data, {timeout: 1000000});
      //await Ajax.post('https://localhost:44305/api/Charlie/GeneraSalidas', payload.data, {timeout: 1000000});
    }
  };
}

const charlieModule = new CharlieModule();
export default charlieModule;
