import { Store, Module, ActionContext } from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Ajax from '../../../lib/ajax'
import PageResult from '@/store/entities/page-result';
import ListMutations from '../list-mutations'
import Transferencia from '@/store/entities/OZONE/transferencia'
import PageResultTransferencia from '@/store/entities/OZONE/transferencias-page-result'
import TransferenciaDash from '@/store/entities/OZONE/dashboardsTransferencias'

interface TransferenciaState extends ListState<Transferencia> {
    editTransferencia: Transferencia,
    dashboard: TransferenciaDash,
    totalCountCampos: number,
    totalCampanas: number,
    totalImporte: number,
    currentPageCampos: number,
    pageSizeCampos: number,
    download: string
}

class TransferenciaModule extends ListModule<TransferenciaState, any, Transferencia>{
    state = {
        totalCount: 0,
        totalCampanas: 0,
        currentPage: 1,
        pageSize: 10,
        list: new Array<Transferencia>(),
        loading: false,
        editTransferencia: new Transferencia(),
        dashboard: new TransferenciaDash(),
        pageSizeCampos: 10,
        download: null,
        totalImporte: 0,
    }
    actions = {
        async getAll(context: ActionContext<TransferenciaState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.post('/api/services/app/Transferencia/FindAll', payload.data);
            context.state.loading = false;
            let page = reponse.data.result as PageResultTransferencia<Transferencia>;
            console.log(page);
            context.state.totalCount = page.totalCount;
            context.state.totalCampanas = page.camapanas;
            context.state.totalImporte = page.total;
            context.state.list = page.items;
        },
        async downloadAll(context: ActionContext<TransferenciaState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.post('/api/services/app/Transferencia/DownloadAll', payload.data);
            context.state.loading = false;
            context.state.download = reponse.data.result;
        },
        async get(context: ActionContext<TransferenciaState, any>, payload: any) {
            let response = await Ajax.get('/api/services/app/Transferencia/GetTransferenciaForView?Id=' + payload.id);
            context.state.editTransferencia = response.data.result.transferencia
            return response.data.result as Transferencia;
        },
        async getDashboard(context: ActionContext<TransferenciaState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.post('/api/services/app/Transferencia/FindDashboard', payload.data);
            context.state.loading = false;
            context.state.dashboard = reponse.data.result as TransferenciaDash;
        },
        async importar(context: ActionContext<TransferenciaState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.post('/api/services/app/Transferencia/ImportarTransferencia', payload.data);
            context.state.loading = false;
        },
        async forzar(context: ActionContext<TransferenciaState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.post('/api/services/app/Transferencia/ForzarTransferencia', payload.data);
            context.state.loading = false;
        },
        async urgent(context: ActionContext<TransferenciaState, any>, payload: any) {
            let reponse = await Ajax.post('/api/services/app/Transferencia/EnviarTransferenciaUrgente', payload.data);
        },
        async rechazar(context: ActionContext<TransferenciaState, any>, payload: any) {
            let reponse = await Ajax.post('/api/services/app/Transferencia/RechazarTransferencia', payload.data);
        },
        async reenviar(context: ActionContext<TransferenciaState, any>, payload: any) {
            let reponse = await Ajax.post('/api/services/app/Transferencia/ReenviarTransferencia', payload.data);
            return reponse.data.result;
        },
        async editar(context: ActionContext<TransferenciaState, any>, payload: any) {
            let reponse = await Ajax.post('/api/services/app/Transferencia/EditarTransferencia', payload.data);
        },
        async aparcar(context: ActionContext<TransferenciaState, any>, payload: any) {
            let reponse = await Ajax.post('/api/services/app/Transferencia/AparcarTransferencia', payload.data);
        },
        async reanudar(context: ActionContext<TransferenciaState, any>, payload: any) {
            let reponse = await Ajax.post('/api/services/app/Transferencia/ResumirTransferencia', payload.data);
        },
        async aprobar(context: ActionContext<TransferenciaState, any>, payload: any) {
            let reponse = await Ajax.post('/api/services/app/Transferencia/AprobarTransferencia', payload.data);
        },
        async desaprobar(context: ActionContext<TransferenciaState, any>, payload: any) {
            let reponse = await Ajax.post('/api/services/app/Transferencia/DesaprobarTransferencia', payload.data);
        },
    };
    mutations = {
        setCurrentPage(state: TransferenciaState, page: number) {
            state.currentPage = page;
        },
        setPageSize(state: TransferenciaState, pagesize: number) {
            
            state.pageSize = pagesize;
        },
        edit(state: TransferenciaState, transferencia: Transferencia) {
            state.editTransferencia = transferencia;
        },
    }
}
const transferenciaModule = new TransferenciaModule();
export default transferenciaModule;