import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Ajax from '../../../lib/ajax'
import ListMutations from '../list-mutations'
import ParticipacionDato from '@/store/entities/OZONE/participacionDato';
import Result from '@/store/entities/result';

interface ParticipacionDatoState extends ListState<ParticipacionDato>{
    editParticipacionDato:ParticipacionDato,
}
class ParticipacionDatoMutations extends ListMutations<ParticipacionDato>{

}
class ParticipacionDatoModule extends ListModule<ParticipacionDatoState,any, ParticipacionDato>{
    state={
        totalCount:0,
        currentPage:0,
        pageSize:0,
        list: new Array< ParticipacionDato>(),
        loading:false,
        editParticipacionDato:new  ParticipacionDato(),
    }
    actions={
        async getAll(context:ActionContext<ParticipacionDatoState,any>,payload:any){
            context.state.loading=true;
            let reponse=await Ajax.get('/api/services/app/ParticipacionDato/GetAll',{params:payload.data});
            context.state.loading=false;
            let page=reponse.data.result as Result<ParticipacionDato>;
            context.state.list=page.items;
        },
        async create(context: ActionContext<ParticipacionDatoState, any>, payload: any) {
            await Ajax.post('/api/services/app/ParticipacionDato/CreateOrEdit',payload.data);
        },
        async createOrEditList(context: ActionContext<ParticipacionDatoState, any>, payload: any) {
            var response = await Ajax.post('/api/services/app/ParticipacionDato/CreateOrEditList',payload.data, {timeout:100000});
            console.log(response);
        },
        async update(context:ActionContext<ParticipacionDatoState,any>,payload:any){
            await Ajax.post('/api/services/app/ParticipacionDato/CreateOrEdit',payload.data);
        },
        async delete(context:ActionContext<ParticipacionDatoState,any>,payload:any){
            await Ajax.delete('/api/services/app/ParticipacionDato/Delete?Id='+payload.data.id);
        },
        async get(context:ActionContext<ParticipacionDatoState,any>,payload:any){
            let reponse=await Ajax.get('/api/services/app/ParticipacionDato/GetParticipacionDatoForEdit?Id='+payload.id);
            return reponse.data.result as ParticipacionDato;
        },
    };
    mutations={
        setCurrentPage(state:ParticipacionDatoState,page:number){
            state.currentPage=page;
        },
        setPageSize(state:ParticipacionDatoState,pagesize:number){
            state.pageSize=pagesize;
        },
        edit(state:ParticipacionDatoState,estadoCampana:ParticipacionDato){
            state.editParticipacionDato=estadoCampana;
        }
    }
}
const participacionDatoStateModule=new ParticipacionDatoModule();
export default participacionDatoStateModule;