import Entity from '../entity'
import Campo from './campo';
import { EstadoParticipacion } from './estadoParticipacion';
import SubEstadoParticipacion from './subEstadoParticipacion';
export default class ProgramacionTransferenciaCampana extends Entity<number>{

     campanaId : number;
     cuentasEmisorasId : number;
     pendiente : boolean;
     fechaInicio : Date;
     fechaFin : Date;
     tipoFrecuencia : string;
     frecuencia : string;
     motivo : string;
     destinatarios : string;
     ordenCompra : string;
     pausa : boolean;
     campo : Campo;
     estadoParticipacion : EstadoParticipacion;
     subEstadoParticipacion : SubEstadoParticipacion;
     formaPago: number;
     limitePorTransferencia:number;
     terminalBizumId:number;
}