import { Store, Module, ActionContext } from 'vuex'
import Home from '@/store/entities/OZONE/home'
import ListModule from '../list-module'
import ListState from '../list-state'
import Ajax from '../../../lib/ajax'
import PageResult from '@/store/entities/page-result';
import ListMutations from '../list-mutations'
import Participacion from '@/store/entities/OZONE/participacion'
import Ticket from '@/store/entities/OZONE/ticket';
import AppConsts from './../../../lib/appconst';
import Campana from '@/store/entities/OZONE/campana';

interface HomeState extends ListState<Home> {
    listparticipacion: Array<Home>,
    pData: Array<number>,
    pLabales: Array<string>,
    pHoy: number,
    pUlt: number,
    cTot: number,
    cAct: number,
    cCons: number,
}
class HomeMutations extends ListMutations<Home>{

}
class HomeModule extends ListModule<HomeState, any, Home>{
    state = {
        totalCount: 0,
        currentPage: 1,
        pageSize: 10,
        list: new Array<Home>(),
        loading: false,
        pData: new Array<number>(),
        pLabales: new Array<string>(),
        pHoy: 0,
        pUlt: 0,
        cTot: 0,
        cAct: 0,
        cCons: 0,
    }

    actions = {

        async getHome(context: ActionContext<HomeState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.get('/api/services/app/Home/GetDatosHome', payload.data);
            context.state.loading = false;
            let page = response.data.result as PageResult<Home>;
            context.state.totalCount = page.totalCount;
            context.state.list = page.items;
        },
        async getParticipacionesHome(context: ActionContext<HomeState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.get('/api/services/app/Home/GetParticipacionesHome', payload.data);
            context.state.loading = false;
            console.log(response.data.result);
            //    context.state.totalCount = response.data.result.participacionesHoy;
            context.state.pHoy = response.data.result.participacionesHoy;
            context.state.pUlt = response.data.result.participacionesUlt;
            context.state.pData = response.data.result.data;
            context.state.pLabales = response.data.result.labels;
            context.state.cTot = response.data.result.totalCampanas;
            context.state.cCons = response.data.result.campanasConst;
            context.state.cAct = response.data.result.campanasActivas;
            context.state.list =  response.data.result.infoCampanas;
            //    let page=reponse.data.result as PageResult<Home>;
            //    context.state.totalCount = page.totalCount;
            //    context.state.listparticipacion = page.items;
        }

    };

}
const homeModule = new HomeModule();
export default homeModule;