import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Ajax from '../../../lib/ajax'
import PageResult from '@/store/entities/page-result';
import ListMutations from '../list-mutations';
import Lectura from '@/store/entities/OZONE/Franqueo/lectura';
import Maquina from '../../entities/OZONE/Franqueo/maquinas';
import Produto from '../../entities/OZONE/Franqueo/producto';
import Ambito from '../../entities/OZONE/Franqueo/ambito';
import Tramo from '../../entities/OZONE/Franqueo/tramo';


interface FranqueoState extends ListState<Lectura>{
    maquinasList: Array<Maquina>,
    productos: Array<Produto>,
    ambitos: Array<Ambito>,
    tramos: Array<Tramo>
}

class FranqueoMutations extends ListMutations<Lectura>{

}

class FranqueoModule extends ListModule<FranqueoState, any, Lectura>{
    state = {
        totalCount :0,
        currentPage:1,
        pageSize:10,
        list: new Array<Lectura>(),
        maquinasList: new Array<Maquina>(),
        loading:false,
    }
    actions = {
        async getMaquinas(context:ActionContext<FranqueoState,any>){
            context.state.loading = true;
            let response = await Ajax.get("/api/services/app/Franqueo/GetMaquinas", { timeout: 100000 });
            context.state.loading = false;
            context.state.maquinasList = response.data.result;
            console.log(response.data.result)
            return response.data.result as Array<Maquina>;
        },
        async editMaquina(context: ActionContext<FranqueoState, any>, payload: any){
            context.state.loading = true;
            let response = await Ajax.post("/api/services/app/Franqueo/EditMaquinaEstado", payload.data)
            context.state.loading = false;
        },
        async getProductos(context: ActionContext<FranqueoState, any>){
            context.state.loading = true;
            let response = await Ajax.get("/api/services/app/Franqueo/GetProductos", {timeout: 100000});
            context.state.productos = response.data.result as Array<Produto>;
            context.state.loading = false;
        },
        async getTramos(context: ActionContext<FranqueoState, any>, payload: any){
            context.state.loading = true;
            let response = await Ajax.get("/api/services/app/Franqueo/GetTramos", {params: payload.data, timeout: 100000});
            context.state.loading = false;
            return response.data.result;
        },
        async getAmbitos(context: ActionContext<FranqueoState, any>, payload: any){
            context.state.loading = true;
            let response = await Ajax.get("/api/services/app/Franqueo/GetAmbitos", {params: payload.data, timeout: 100000});
            context.state.loading = false;
            return response.data.result;
        },
        async getOpciones(context: ActionContext<FranqueoState, any>, payload: any){
            context.state.loading = true;
            let response = await Ajax.get("/api/services/app/Franqueo/GetOpciones", {params: payload.data, timeout: 100000});
            context.state.loading = false;
            return response.data.result;
        }
    }
}

const franqueoModule=new FranqueoModule();
export default franqueoModule;