<template>
	
	<!-- Layout Footer -->
	<a-layout-footer>
		<a-row type="flex">
			<a-col :span="24" :md="12">

				<!-- Footer Copyright Notice -->
				<p class="copyright">
					© 2023, <a href="https://agenciapeople.es/">Agencia People</a>. - {{ environment }}
				</p>
				<!-- / Footer Copyright Notice -->

			</a-col>
			<a-col :span="24" :md="12" class="footer-control">

				<!-- Footer Navigation Menu -->
				<!-- <a-menu mode="horizontal">
					<a-menu-item @click="showDrawer">Correos</a-menu-item>
				</a-menu> -->
				<!-- / Footer Navigation Menu -->

			</a-col>
		</a-row>
		<!-- <CorreoTracker :visible="visible" @close="onClose"></CorreoTracker> -->
	</a-layout-footer>
	<!-- / Layout Footer -->
</template>
<script>
import CorreoTracker from '../../Correos/CorreoTracker.vue'

	export default ({
		components: {
			CorreoTracker
		},
		data() {
			return {
				visible: false,
				environment: process.env.VUE_APP_ENVIRONMENT
			}
		},
		methods: {
		showDrawer() {
			this.visible = true;
		},
		onClose() {
			this.visible = false;
		},
	},
	})

</script>
