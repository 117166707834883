import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Ajax from '../../../lib/ajax'
import PageResult from '@/store/entities/page-result';
import ListMutations from '../list-mutations'
import Importacion from '@/store/entities/OZONE/importacion'
interface ImportacionState extends ListState<Importacion>{
    editImportacion : Importacion
    
}
class ImportacionMutations extends ListMutations<Importacion>{
    
}
class ImportacionModule extends ListModule<ImportacionState,any, Importacion>{
    state={
       totalCount :0,
        currentPage:1,
        pageSize:10,
        list: new Array<Importacion>(),
        loading:false,
        editImportacion :new Importacion(),

        
    }
    actions={
        async getAll(context:ActionContext<ImportacionState,any>,payload:any){
            
            context.state.loading = true;
            let reponse=await Ajax.get('/api/services/app/Importaciones/GetAll',{params:payload.data});
            context.state.loading=false;
            let page=reponse.data.result as PageResult<Importacion>;
            context.state.totalCount=page.totalCount;
            context.state.list=page.items;
        },
          
        
    };
    mutations={
        setCurrentPage(state:ImportacionState,page:number){
            state.currentPage=page;
        },     
        setPageSize(state:ImportacionState,pagesize:number){
            state.pageSize=pagesize;
        },
        edit(state:ImportacionState,importaciones:Importacion){
            
            state.editImportacion=importaciones;          
        },
        
    }
    
}
const importacionModule=new ImportacionModule();
export default importacionModule;