import clickOutside from '@/directives/click-ouside.js';
import {mask} from 'vue-the-mask'
//import { Loading } from 'element-ui'
import disableAll from '@/directives/disable-all';

let vMyDirective = {}
vMyDirective.install = function install (_Vue) {
    let _uid = 'vue-directive-loading' + Date.now().toString('16')
    _Vue.directive('loading', {
      inserted: function (el, binding) {
        let spinner = document.createElement('div');
        let span = document.createElement('span');
        let dot = document.createElement('i');
        dot.className = "ant-spin-dot-item";

        spinner.className = "ant-spin ant-spin-spinning";
        span.className = "ant-spin-dot ant-spin-dot-spin";
        span.style.top = '50%'

        span.appendChild(dot);
        dot = document.createElement('i');
        dot.className = "ant-spin-dot-item";

        span.appendChild(dot);
        dot = document.createElement('i');
        dot.className = "ant-spin-dot-item";

        span.appendChild(dot);
        dot = document.createElement('i');
        dot.className = "ant-spin-dot-item";

        span.appendChild(dot);

        spinner.appendChild(span);

        spinner.id = _uid

        spinner.style.display = binding.value ? 'block' : 'none'
        spinner.style.top = 0
        spinner.style.left = 0
        spinner.style['background-color'] = 'rgba(128,128,128,0.05)'
        spinner.style['margin-right'] = 'auto'
        spinner.style['margin-left'] = 'auto'
        spinner.style.position = 'absolute'
        spinner.style.width = '100%'
        spinner.style.height = '100%'

        el.childNodes.forEach((item) => {
          item.style.display = binding.value ? 'none' : ''
        })

        el.appendChild(spinner)
      },
      update: function (el, binding, vnode) {
        el.childNodes.forEach((item) => {
          if(item.id === _uid) {
            item.style.display = binding.value ? 'block' : 'none'
            return
          }
          
          item.style.display = binding.value ? 'none' : ''
        })
      }
    })
  }

/**
 * You can register global directives here and use them as a plugin in your main Vue instance
 */

const GlobalDirectives = {
  install(Vue) {
    Vue.directive('click-outside', clickOutside);
    Vue.directive('mask', mask);
    Vue.use(vMyDirective);
    Vue.directive("disable-all", disableAll);
  }
};

export default GlobalDirectives;
