import url from './url'
const AppConsts= {
    userManagement:{
        defaultAdminUserName: 'admin'
    },
    localization:{
        defaultLocalizationSourceName: 'OZONE'
    },
    authorization:{
        encrptedAuthTokenName: 'enc_auth_token'
    },
    appBaseUrl: process.env.BASE_URL,
    remoteServiceBaseUrl: url.endsWith('/') ? url.slice(0, -1) : url,
    defaultTenant: 'People',
    procterGamble: 'Procter&Gamble',
    ApiKey:"PlJqLUJAemJETjZNQlluUy5FJ2A7aDMrLyEiOThQLXkhRj55eX5KYj0hPWpYXU4+",
    codePost:"=QeuT/DDxahfUY0PGLybRGbMzreoMRwPzEEK604UptjaLcsP1yQxKdA==",
    codeGet:"=E/dgYaVo35mioi1GVW6al9waAneZnaYC7m6UbCm/cWkuflGeADvqEQ==&uploadId="
}
export default AppConsts