import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Ajax from '../../../lib/ajax'
import PageResult from '@/store/entities/page-result';
import ListMutations from '../list-mutations'
import ServicioMensajeria from '@/store/entities/OZONE/servicioMensajeria'
interface ServicioMensajeriaState extends ListState<ServicioMensajeria>{
    editServicioMensajeria : ServicioMensajeria
    
}
class ServicioMensajeriaMutations extends ListMutations<ServicioMensajeria>{
    
}
class ServicioMensajeriaModule extends ListModule<ServicioMensajeriaState,any, ServicioMensajeria>{
    state={
       totalCount :0,
        currentPage:1,
        pageSize:10,
        list: new Array<ServicioMensajeria>(),
        loading:false,
        editServicioMensajeria :new ServicioMensajeria(),

        
    }
    actions={
        async getAll(context:ActionContext<ServicioMensajeriaState,any>,payload:any){
            
            context.state.loading = true;
            let reponse=await Ajax.get('/api/services/app/ServicioMensajeria/GetAll',{params:payload.data});
            context.state.loading=false;
            let page=reponse.data.result as PageResult<ServicioMensajeria>;
            context.state.totalCount=page.totalCount;
            context.state.list=page.items;
        },
          
        async create(context: ActionContext<ServicioMensajeriaState, any>, payload: any) {
            
            await Ajax.post('/api/services/app/ServicioMensajeria/CreateOrEdit',payload.data);
            
        },
        async update(context:ActionContext<ServicioMensajeriaState,any>,payload:any){
            await Ajax.post('/api/services/app/ServicioMensajeria/CreateOrEdit',payload.data);
        },
        async delete(context:ActionContext<ServicioMensajeriaState,any>,payload:any){
            await Ajax.delete('/api/services/app/ServicioMensajeria/Delete?Id='+payload.data.id);
        },
        async get(context: ActionContext<ServicioMensajeriaState, any>, payload: any) {
            let response = await Ajax.get('/api/services/app/ServicioMensajeria/GetGrupoForEdit?Id=' + payload.id);
            //context.state.editGrupo = response.data.result.grupo
            return response.data.result.grupo as ServicioMensajeria;
        },
    };
    mutations={
        setCurrentPage(state:ServicioMensajeriaState,page:number){
            state.currentPage=page;
        },     
        setPageSize(state:ServicioMensajeriaState,pagesize:number){
            state.pageSize=pagesize;
        },
        edit(state:ServicioMensajeriaState,ServicioMensajeria:ServicioMensajeria){
            
            state.editServicioMensajeria=ServicioMensajeria;          
        },
        
    }
    
}
const servicioMensajeriaModule = new ServicioMensajeriaModule();
export default servicioMensajeriaModule;