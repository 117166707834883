import Campana from "./campana";

export default class GenerarExportacion {
    campana: Campana;
    mailing: string;
    fileName: string;
    observaciones: string;
    estadoId: number[] | null;
    disenoExportacion: number | null;
    //customExport: ExportDesign;
    from: string | null;
    to: string | null;
}