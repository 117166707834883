
import { Component, Vue } from "vue-property-decorator";
import es_ES from "ant-design-vue/lib/locale-provider/es_ES";
@Component({})
export default class App extends Vue {
  data() {
    return {
      es_ES,
    };
  }
}
