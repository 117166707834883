import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Ajax from '../../../lib/ajax'
import PageResult from '@/store/entities/page-result';
import ListMutations from '../list-mutations'
import Provincia from '@/store/entities/OZONE/provincia'
import Municipio from './../../entities/OZONE/municipio';

interface ProvinciaState extends ListState<Provincia>{
    editProvincia:Provincia,
}
interface MunicipioState extends ListState<Municipio>{
    editMunicipio:Municipio,
}
class ProvinciaMutations extends ListMutations<Provincia>{

}
class ProvinciaModule extends ListModule<ProvinciaState,any, Provincia>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize:10,
        list: new Array< Provincia>(),
        loading:false,
        editProvincia:new  Provincia(),
    }
    actions={
        async getAll(context:ActionContext<ProvinciaState,any>,payload:any){
            context.state.loading = true;
            let reponse=await Ajax.get('/api/services/app/Provincia/GetAll',{params:payload.data});
            context.state.loading=false;
            let page=reponse.data.result as PageResult<Provincia>;
            context.state.totalCount=page.totalCount;
            context.state.list=page.items;
        },
        async GetProvinciByID(context:ActionContext<ProvinciaState,any>,payload:any){
            
            context.state.loading = true;
            let reponse=await Ajax.get('/api/services/app/Provincia/GetProvinciByID',{params:payload.data});
            
            context.state.loading=false;
            let page=reponse.data.result as PageResult<Provincia>;
            
            context.state.editProvincia = page.items[0]
        },
       
       
        async create(context: ActionContext<ProvinciaState, any>, payload: any) {
            await Ajax.post('/api/services/app/Provincia/CreateOrEdit',payload.data);
        },
        async update(context:ActionContext<ProvinciaState,any>,payload:any){
            await Ajax.post('/api/services/app/Provincia/CreateOrEdit',payload.data);
        },
        async delete(context:ActionContext<ProvinciaState,any>,payload:any){
            await Ajax.delete('/api/services/app/Provincia/Delete?Id='+payload.data.id);
        },
       
        
    };
    mutations={
        setCurrentPage(state:ProvinciaState,page:number){
            state.currentPage=page;
        },
        setPageSize(state:ProvinciaState,pagesize:number){
            state.pageSize=pagesize;
        },
        edit(state:ProvinciaState,provincia:Provincia){
            state.editProvincia=provincia;
        }
    }
}
const provinciaModule=new ProvinciaModule();
export default provinciaModule;