import {ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Ajax from '../../../lib/ajax'
import PageResult from '@/store/entities/page-result';
import ListMutations from '../list-mutations'
import Participacion from '@/store/entities/OZONE/participacion'
import Ticket from '@/store/entities/OZONE/ticket';
import EanTicket from '@/store/entities/OZONE/eanTicket';
import ParticipacionGroup from '@/store/entities/OZONE/participacionGroup';
import AppConsts from './../../../lib/appconst';

interface ParticipacionState extends ListState<Participacion>{
    editParticipacion:Participacion,
    modal :  Array<any>,
    currentPageGroup: number,
    totalCountGroup: number,
    loadingGroup: boolean,
    listGroup: Array<ParticipacionGroup>,
    currentView: string
}
class EstadoParticipacionMutations extends ListMutations<Participacion>{

}
class ParticipacionModule extends ListModule<ParticipacionState,any, Participacion>{
    state={
        totalCount:0,
        totalCountGroup: 0,
        currentPage:1,
        currentPageGroup: 1,
        currentView: "group",
        pageSize:10,
        list: new Array<Participacion>(),
        listGroup: new Array<ParticipacionGroup>(),
        loading:false,
        loadingGroup: false,
        editParticipacion:new  Participacion()
    }
    actions={
        async getAll(context:ActionContext<ParticipacionState,any>,payload:any){
            context.state.loading=true;
            let reponse=await Ajax.post('/api/services/app/Participacion/findAll',payload.data)
            context.state.loading=false;
            let page=reponse.data.result as PageResult<Participacion>;
            context.state.totalCount=page.totalCount;
            context.state.list=page.items;
            //return page;
        },
        async getAllGroup(context: ActionContext<ParticipacionState, any>, payload: any) {
            context.state.loadingGroup = true;
            let reponse = await Ajax.post('/api/services/app/Participacion/FindAllGroup', payload.data);
            context.state.loadingGroup = false;
            let page = reponse.data.result as PageResult<ParticipacionGroup>;
            context.state.totalCountGroup = page.totalCount;
            context.state.listGroup = page.items;
        },
        async getHome(context:ActionContext<ParticipacionState,any>,payload:any){
            
               context.state.loading=true;
               let reponse=await Ajax.get('/api/services/app/Home/GetDatosHome',payload.data);
               context.state.loading=false;
               let page=reponse.data.result as PageResult<Participacion>;
               context.state.totalCount=page.totalCount;
               context.state.list=page.items;
           },
        async create(context: ActionContext<ParticipacionState, any>, payload: any) {
            await Ajax.post('/api/services/app/Participacion/CreateOrEdit',payload.data,{timeout:100000});
        },
        async update(context: ActionContext<ParticipacionState, any>, payload: any) {
            let result = await Ajax.post('/api/services/app/Participacion/CreateOrEdit',payload.data,{timeout:100000});
            console.log("Result en update call desde vue " + result)
        },
        async forzarTransferencia(context: ActionContext<ParticipacionState, any>, payload: any) {
            let result = await Ajax.post('/api/services/app/Participacion/ForzarTransferencia', payload.data, {timeout:100000});
            return result.data;
        },
        async delete(context:ActionContext<ParticipacionState,any>,payload:any){
            await Ajax.delete('/api/services/app/Participacion/Delete?Id='+payload.data.id);
        },
        async get(context:ActionContext<ParticipacionState,any>,payload:any){
            let response = await Ajax.get('/api/services/app/Participacion/GetParticipacionForEdit?Id=' + payload.id);
            context.state.editParticipacion = response.data.result.participacion
            return response.data.result as Participacion;
        },
        async getImages(context:ActionContext<ParticipacionState,any>,payload:any){
            let response = await Ajax.get('/api/services/app/Participacion/GetImagesParticipacionForEdit?Id=' + payload.id);
            context.state.editParticipacion.validacionFileContent = response.data.result.participacion.validacionFileContent
            context.state.editParticipacion.validacion2FileContent = response.data.result.participacion.validacion2FileContent
            context.state.editParticipacion.eanFileContent = response.data.result.participacion.eanFileContent
            context.state.editParticipacion.dniFrontalFileContent = response.data.result.participacion.dniFrontalFileContent
            context.state.editParticipacion.dniTraseraFileContent = response.data.result.participacion.dniTraseraFileContent
            context.state.editParticipacion.fromFileFileContent = response.data.result.participacion.fromFileFileContent
            context.state.editParticipacion.imagenAuxiliar1FileContent = response.data.result.participacion.imagenAuxiliar1FileContent
            context.state.editParticipacion.imagenAuxiliar2FileContent = response.data.result.participacion.imagenAuxiliar2FileContent
            context.state.editParticipacion.imagenAuxiliar3FileContent = response.data.result.participacion.imagenAuxiliar3FileContent
            context.state.editParticipacion.imagenAuxiliar4FileContent = response.data.result.participacion.imagenAuxiliar4FileContent
            return response.data.result as Participacion;
        },
        async deleteTicketFile(context:ActionContext<ParticipacionState,any>,payload:any){
            await Ajax.delete('/api/services/app/Participacion/DeleteTicketFile?Id='+payload.data.id);
        },
         async deleteTicket2File(context:ActionContext<ParticipacionState,any>,payload:any){
            await Ajax.delete('/api/services/app/Participacion/DeleteTicket2File?Id='+payload.data.id);
        },
          async deleteEanFile(context:ActionContext<ParticipacionState,any>,payload:any){
              
            await Ajax.delete('/api/services/app/Participacion/DeleteEanFile?Id='+payload.data.id);
        },
          async deleteDniFrontalFile(context:ActionContext<ParticipacionState,any>,payload:any){
            await Ajax.delete('/api/services/app/Participacion/DeleteDniFrontalFile?Id='+payload.data.id);
        },
           async deleteDniTraseraFile(context:ActionContext<ParticipacionState,any>,payload:any){
            await Ajax.delete('/api/services/app/Participacion/DeleteDniTraseraFile?Id='+payload.data.id);
        },
            async DeleteFromFileFile(context:ActionContext<ParticipacionState,any>,payload:any){
            await Ajax.delete('/api/services/app/Participacion/DeleteFromFileFile?Id='+payload.data.id);
        },
        async DeleteImagenAuxiliar1(context:ActionContext<ParticipacionState,any>,payload:any){
            await Ajax.delete('/api/services/app/Participacion/DeleteImagenAuxiliar1?Id='+payload.data.id);
        },
        async DeleteImagenAuxiliar2(context:ActionContext<ParticipacionState,any>,payload:any){
            await Ajax.delete('/api/services/app/Participacion/DeleteImagenAuxiliar2?Id='+payload.data.id);
        },
        async DeleteImagenAuxiliar3(context:ActionContext<ParticipacionState,any>,payload:any){
            await Ajax.delete('/api/services/app/Participacion/DeleteImagenAuxiliar3?Id='+payload.data.id);
        },
        async DeleteImagenAuxiliar4(context:ActionContext<ParticipacionState,any>,payload:any){
            await Ajax.delete('/api/services/app/Participacion/DeleteImagenAuxiliar4?Id='+payload.data.id);
        },
        async getTicketDto(context: ActionContext<ParticipacionState, any>, payload: any) {
            let response = await Ajax.get('/api/services/app/Participacion/getTicketDto?participacionId=' + payload.data.participacionId + '&imagenValidacion=' + payload.data.imagenValidacion);
            if (response.data.result !== null) {
                let index = context.state.editParticipacion.ticket.findIndex((x) => x.participacionId == payload.data.participacionId && x.imagenValidacion == payload.data.imagenValidacion)
                if (index < 0) {
                    context.state.editParticipacion.ticket.push(response.data.result)
                } else {
                    context.state.editParticipacion.ticket.splice(index, 1)
                    context.state.editParticipacion.ticket.push(response.data.result)
                }
            }
            
        },
        async getTicketEanDto(context: ActionContext<ParticipacionState, any>, payload: any) {
            let response = await Ajax.get('/api/services/app/Participacion/getTicketDtoEan?participacionId=' + payload.data.participacionId + '&imagenValidacion=' + payload.data.imagenValidacion);
            
            if (response.data.result !== null) {
               context.state.editParticipacion.eanTicket= response.data.result
            }
            
        },
        async postfile(context: ActionContext<ParticipacionState, any>, payload: any) {
            
            let response = await Ajax.post('https://pdmuploadfunction.azurewebsites.net/api/UploadBlob?code'+AppConsts.codePost, payload.data, { headers: {'content-type': 'multipart/form-data'} });
            
            return response
        },
        async postdata(context: ActionContext<ParticipacionState, any>, payload: any) {
            
            let response = await Ajax.get('https://pdmresultfunction.azurewebsites.net/api/ResultFunction?code'+AppConsts.codeGet+payload.data);

            return response.data
        },
        async getStatus(context: ActionContext<ParticipacionState, any>, payload: any) {
            
            let response = await Ajax.get('https://docai-pdm-api.entornopruebas.com/api/v1/tickets/' + payload.data + '/status', { headers: { 'X-ApiKey': AppConsts.ApiKey } });
            
            return response.data
        },
        async getInfo(context: ActionContext<ParticipacionState, any>, payload: any) {
            let response = await Ajax.get('https://docai-pdm-api.entornopruebas.com/api/v1/tickets/' + payload.data, { headers: { 'X-ApiKey': AppConsts.ApiKey } });
            

            return response.data
        },
        async postfilEan(context: ActionContext<ParticipacionState, any>, payload: any) {
            
            let response = await Ajax.post('/api/services/app/Participacion/postfileEan', payload.data);
            
            return response.data
        },
        async postfilEanManual(context: ActionContext<ParticipacionState, any>, payload: any) {
            
            let response = await Ajax.post('https://barcodetoean.azurewebsites.net/api/barcodereader' , payload.data,{ headers: {'content-type': 'multipart/form-data' ,'Access-Control-Allow-Origin': '*'} } );
            
            return response
        },
        async deleteItemTicket(context:ActionContext<ParticipacionState,any>,payload:any){
            await Ajax.delete('/api/services/app/Participacion/DeleteItemTicket?Id='+payload.data.id);
        },
        async deleteTicket(context:ActionContext<ParticipacionState,any>,payload:any){
            await Ajax.delete('/api/services/app/Participacion/DeleteTicket?Id='+payload.data.id);
        },
        async getComentario(context:ActionContext<ParticipacionState,any>,payload:any){
            let response = await Ajax.get('/api/services/app/Participacion/GetParticipacionComentarios?Id=' + payload.id);
            context.state.editParticipacion.comentarios = response.data.result;
            return response.data.result;
        },
        async postComentario(context: ActionContext<ParticipacionState, any>, payload: any) {
            let result = await Ajax.post('/api/services/app/Participacion/CrearParticipacionComentario',payload.data,{timeout:100000});
        },
        async deleteComentario(context: ActionContext<ParticipacionState, any>, payload: any) {
            let result = await Ajax.post('/api/services/app/Participacion/EliminarParticipacionComentario',payload.data,{timeout:100000});
        },
        async generarExportacion(context: ActionContext<ParticipacionState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.post('/api/services/app/Participacion/GenerarExportacion', payload.data, {timeout:1000000});
            context.state.loading = false;
            return reponse.data.result;
        },
    };
    mutations={
        setCurrentPage(state:ParticipacionState,page:number){
            state.currentPage=page;
        },
        setCurrentPageGroup(state: ParticipacionState, page: number) {
            state.currentPageGroup = page;
        },
        setPageSize(state:ParticipacionState,pagesize:number){
            state.pageSize=pagesize;
        },
        setCurrentView(state: ParticipacionState, view: string){
            state.currentView = view;
        },
        edit(state: ParticipacionState, participacion: Participacion) {
            state.editParticipacion=participacion;
        },
        saveTicket(state:ParticipacionState,ticket:Array<Ticket>){
            state.editParticipacion.ticket=ticket;
        },
        saveEan(state:ParticipacionState,ean:Array<EanTicket>){
            
            state.editParticipacion.eanTicket=ean;
        },
        guardarbusqueda(state:ParticipacionState, modal:Array<any>){
            state.modal = modal
        },
        clearData(state: ParticipacionState) {
            state.list = [];
            state.currentPageGroup = 1;
            state.currentPage = 1;
            state.pageSize = 10;
        }
    }
}
const participacionModule=new ParticipacionModule();
export default participacionModule;