import Entity from '../entity'
import  Cliente  from '@/store/entities/OZONE/cliente';
import Transferencia from './transferencia';
import Envio from './envio';
export default class Importacion extends Entity<number>{
    origen: string;
    fichero: string;
    mailing: string;
    observaciones: string;
    estadoInicialEnvioId: number;
    fechaEnviado: string;
    transferencias: Array<Transferencia>;
    envios: Array<Envio>;
}