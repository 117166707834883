<template>
  <!-- Main Sidebar -->
  <a-layout-sider
    collapsible
    class="sider-primary"
    breakpoint="lg"
    collapsed-width="0"
    width="250px"
    :collapsed="sidebarCollapsed"
    @collapse="$emit('toggleSidebar', !sidebarCollapsed)"
    :trigger="null"
    :class="[
      'ant-layout-sider-' + sidebarColor,
      'ant-layout-sider-' + sidebarTheme,
    ]"
    theme="light"
    :style="{ backgroundColor: 'transparent' }"
  >
    <div class="brand">
      <img
        id="people-logo-sidebar"
        :src="tenantImg"
        style="max-height: 5vh"
      /><span style="margin-left: 3vh">OZONE</span>
    </div>
    <hr />

    <!-- Sidebar Navigation Menu -->
    <a-menu
      theme="light"
      mode="inline"
      v-for="item in menuList"
      v-bind:key="item.name"
    >
      <slot v-if="item.children.length == 1 || item.children[0].path === ''">
        <a-menu-item>
          <router-link
            :to="item.path + '/' + item.children[0].path"
            :href="'/#' + item.path + '/' + item.children[0].path"
          >
            <span class="icon">
              <i :class="item.icon"></i>
            </span>
            <span class="label">{{ item.meta.title }}</span>
          </router-link>
        </a-menu-item>
      </slot>
      <slot v-if="item.children.length > 1 && item.children[0].path !== ''">
        <a-sub-menu>
          <template slot="title">
            <span class="icon">
              <i :class="item.icon ? item.icon : 'fas fa-times'"></i>
            </span>
            <span class="label">{{ item.meta.title }}</span>
          </template>

          <a-menu-item
            v-for="sitem in item.children.filter(
              (i) =>
                i.children == null ||
                (i.children != null &&
                  (i.children.length == 1 || i.children[0].path == ''))
            )"
            v-bind:key="sitem.name"
            style="margin-left: 10px"
          >
            <router-link
              :to="item.path + '/' + sitem.path"
              :href="'/#' + item.path + '/' + sitem.path"
            >
              <span class="icon">
                <i :class="sitem.icon ? sitem.icon : 'fas fa-times'"></i>
              </span>
              <span class="label">{{ sitem.meta.title }}</span>
            </router-link>
          </a-menu-item>

          <a-sub-menu
            v-for="ssitem in item.children.filter((i) => i.children != null)"
            v-bind:key="ssitem.name"
            style="margin-left: 10px"
          >
            <template slot="title">
              <span class="icon">
                <i :class="ssitem.icon ? ssitem.icon : 'fas fa-times'"></i>
              </span>
              <span class="label">{{ ssitem.meta.title }}</span>
            </template>

            <a-menu-item
              v-for="sitem in ssitem.children"
              v-bind:key="sitem.name"
              style="margin-left: 10px"
            >
              <router-link
                :to="item.path + ssitem.path + sitem.path"
                :href="'/#' + item.path + ssitem.path + sitem.path"
              >
                <span class="icon">
                  <i :class="sitem.icon ? sitem.icon : 'fas fa-times'"></i>
                </span>
                <span class="label">{{ sitem.meta.title }}</span>
              </router-link>
            </a-menu-item>
          </a-sub-menu>
        </a-sub-menu>
      </slot>
    </a-menu>
    <!-- / Sidebar Navigation Menu -->
  </a-layout-sider>
  <!-- / Main Sidebar -->
</template>

<script>
export default {
  props: {
    // Sidebar collapsed status.
    sidebarCollapsed: {
      type: Boolean,
      default: false,
    },

    // Main sidebar color.
    sidebarColor: {
      type: String,
      default: "primary",
    },

    // Main sidebar theme : light, white, dark.
    sidebarTheme: {
      type: String,
      default: "light",
    },
    menuList: {
      type: Array,
      default: [],
    },
     tenantImg:{
        type: String,
        default: "img/Group-6.png"
    }
  },
};
</script>
<style>
.ant-menu-submenu > .ant-menu {
  background-color: transparent !important;
}
.ant-menu-submenu-title {
  padding-left: 16px !important;
}
.ant-menu-submenu > .ant-menu-item {
  margin-left: 5px !important;
}

@font-face {
  font-family: "Prueba";
  src: url("/fonts/Nevrada_Neue.otf");
}

.ozn {
  font-family: "Prueba";
  font-size: 35px;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}
</style>
