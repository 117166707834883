import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Ajax from '../../../lib/ajax'
import PageResult from '@/store/entities/page-result';
import ListMutations from '../list-mutations'
import CallCenterCampana from '@/store/entities/OZONE/callCenterCampana'
import CallCenterEstadoParticipacion from '../../entities/OZONE/callCenterEstadoParticipacion';

interface CallCenterCampanaState extends ListState<CallCenterCampana>{
    editCallCenterCampana: CallCenterCampana,
    editCallCenterEstadoParticipacion: CallCenterEstadoParticipacion
}
class EstadoCallCenterCampanaMutations extends ListMutations<CallCenterCampana>{

}
class CallCenterCampanaModule extends ListModule<CallCenterCampanaState,any, CallCenterCampana>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize:10,
        list: new Array<CallCenterCampana>(),
        loading:false,
        editCallCenterCampana: new CallCenterCampana(),
        editCallCenterEstadoParticipacion: new CallCenterEstadoParticipacion()
    }
    actions={
        async getAll(context:ActionContext<CallCenterCampanaState,any>,payload:any){
            context.state.loading=true;
            let reponse=await Ajax.get('/api/services/app/CallCenterCampana/GetAll',{params:payload.data});
            context.state.loading=false;
            let page=reponse.data.result as PageResult<CallCenterCampana>;
            context.state.totalCount=page.totalCount;
            context.state.list = page.items;
        },
        async create(context: ActionContext<CallCenterCampanaState, any>, payload: any) {
            await Ajax.post('/api/services/app/CallCenterCampana/CreateOrEdit',payload.data);
        },
        async update(context: ActionContext<CallCenterCampanaState,any>,payload:any){
            await Ajax.post('/api/services/app/CallCenterCampana/CreateOrEdit',payload.data);
        },
        async delete(context: ActionContext<CallCenterCampanaState,any>,payload:any){
            await Ajax.delete('/api/services/app/CallCenterCampana/Delete?Id='+payload.data.id);
        },
        async deleteCallCenterEstadoParticipacion(context:ActionContext<CallCenterCampanaState,any>,payload:any){
            await Ajax.delete('/api/services/app/CallCenterCampana/DeleteCallCenterEstadoParticipacion?Id='+payload.data.id);
        },
        async get(context:ActionContext<CallCenterCampanaState,any>,payload:any){
            let response = await Ajax.get('/api/services/app/CallCenterCampana/GetCallCenterForEdit?Id=' + payload.id);
             context.state.editCallCenterCampana = response.data.result.vozitelCampana
            return response.data.result.vozitelCampana as CallCenterCampana;
        },
         async getByCampana(context:ActionContext<CallCenterCampanaState,any>,payload:any){
            let response = await Ajax.get('/api/services/app/CallCenterCampana/GetCallCenterForEditByCampana?CampanaId=' + payload.data.id);
            context.state.editCallCenterCampana = response.data.result.vozitelCampana
            return response.data.result.vozitelCampana as CallCenterCampana;
        },
    };
    mutations={
        setCurrentPage(state: CallCenterCampanaState, page: number){
            state.currentPage=page;
        },
        setPageSize(state: CallCenterCampanaState, pagesize: number){
            state.pageSize=pagesize;
        },
        edit(state: CallCenterCampanaState, callCenterCampana: CallCenterCampana){
            state.editCallCenterCampana = callCenterCampana;
        },
        editEstados(state: CallCenterCampanaState, callCenterEstadoParticipacion: CallCenterEstadoParticipacion){
            state.editCallCenterEstadoParticipacion = callCenterEstadoParticipacion;
        }
    }
}
const callCenterCampanaModule=new CallCenterCampanaModule();
export default callCenterCampanaModule;