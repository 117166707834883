import Entity from '../entity'
export default class ValidateImagenCampana extends Entity<number>{   
    campanaId : number;
    checkedTicket1: boolean;
    checkedTicket2: boolean;
    checkedEan: boolean;
    checkedDNIFrontal: boolean;
    checkedDNITrasero: boolean;
    checkedImagenAuxiliar: boolean;
    checkedImagenAuxiliar1: boolean;
    checkedImagenAuxiliar2: boolean;
    checkedImagenAuxiliar3: boolean;
    checkedImagenAuxiliar4: boolean;
}