import Entity from '../entity'
import Campana from "./campana";
export default class FicheroEnvio extends Entity<number>{
    Campana:Campana 
    envios: number;
    fichero: string;
    informe: string;
    fechaCreacion: string;
    fechaExportacion: string;
    pendiente: boolean;
}