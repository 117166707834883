import Entity from '../entity'
import { EstadoParticipacion } from '@/store/entities/OZONE/estadoParticipacion';
import SubEstadoParticipacion  from '@/store/entities/OZONE/subEstadoParticipacion';
import Ticket from './ticket';
import Transferencia from './transferencia';
import ParticipacionDato from './participacionDato';
import ParticipacionComentario from './participacionComentario';
import EanTicket from './eanTicket';

export default class Participacion extends Entity<number>{
    nombre: string;
    apellido1: string;
    apellido2: string;
    email: string;
    telefono: string;
    fechaNacimiento: string;
    sexo: string;
    dNI: string;
    campanaId: number;
    campanaNombre: string;
    campanaAxapta: string;
    participacionDatoId: Array<number>;
    participacionDato: Array<ParticipacionDato>;
    estadoParticipacion: EstadoParticipacion;
    estadoParticipacionId: number;
    subEstadoParticipacion: SubEstadoParticipacion;
    subEstadoParticipacionId: number;
    ean: string;
    regalo: string;
    eanFileContent: string;
    dniFrontal: string;
    dniFrontalFileContent: string;
    dniTrasera: string;
    dniTraseraFileContent: string;
    fromFile: string;
    fromFileFileContent: string;
    validacion: string;
    validacionFileContent: string;
    validacion2: string;
    validacion2FileContent: string;
    imagenAuxiliar1: string;
    imagenAuxiliar1FileContent: string;
    imagenAuxiliar2: string;
    imagenAuxiliar2FileContent: string;
    imagenAuxiliar3: string;
    imagenAuxiliar3FileContent: string;
    imagenAuxiliar4: string;
    imagenAuxiliar4FileContent: string;
    ticket: Array<Ticket>;
    transferencias: Array<Transferencia>;
    comentarios: Array<ParticipacionComentario>;
    imagesModified: boolean
    eanTicket : Array<EanTicket>;
    formaPago:number;
    
}