// Polyfills for js features used in the Dashboard but not supported in some browsers (mainly IE)
import '@/polyfills';
// Notifications plugin. Used on Notifications page
import Notifications from '@/components/NotificationPlugin';
// Validation plugin used to validate forms
import { configure } from 'vee-validate';
// A plugin file where you could register global components used across the app
import GlobalComponents from './globalComponents';
// A plugin file where you could register global directives
import GlobalDirectives from './globalDirectives';

// Sidebar on the right. Used as a local plugin in DashboardLayout.vue
//import SideBar from '@/components/SidebarPlugin';


// element ui language configuration

// vue-bootstrap
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

//ant-deign
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';


// asset imports
import '../scss/styles.scss';
import '../scss/custom.scss';
import '../scss/muse/app.scss';
import "animate.css";
import '@riophae/vue-treeselect/dist/vue-treeselect.min.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import 'cropperjs/dist/cropper.min.css'
import 'vue-file-agent/dist/vue-file-agent.css';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';
// import '../scss/vendor/nucleo/css/nucleo.css';
import { extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import { messages } from 'vee-validate/dist/locale/es.json';
import VueTheMask from 'vue-the-mask'

import moment from 'moment'



Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message: messages[rule] // assign message
  });
  extend('between', {
  params: ['min', 'max'],
    validate(value, { min, max }) {
    if (moment(value).isValid()) {
      return moment(value) >= moment(min, 'DD/MM/YYYY,true') && moment(value) <= moment(max, 'DD/MM/YYYY,true')
    }  else {
      return parseInt(value) >= parseInt(min) && parseInt(value) <= parseInt(max)
    }
  },
  message: 'Este campo debe estar entre {min} y {max}'
}),
  extend('date_format', {
    validate: (value) => {
      return moment(value, 'DD/MM/YYYY,true').isValid()
    },
    message: 'Este campo debe ser una fecha válida'
  }),
  extend('filtrableDropdownRequired', {
    validate: value => {
      return value['value'] != null && value['value'] !== -1;
    },
    message: 'El campo {_field_} es obligatorio'
  }),
  extend('rangePickerRequired', {
    validate: value => {
      return value != null && value[0] != null && value[1] != null
    },
    message: 'El campo {_field_} es obligatorio'
  }),
  extend('oneOf', {
  validate: (value,other) => {
    return other.some(x=>x.toUpperCase() ===value.toUpperCase());
  },    
  }),
    extend('excluded', {
  validate: (value,other) => {
    return other.some(x=>x.toUpperCase() !==value.toUpperCase());
  },    
})
});
moment.locale('es');
export default {
  install(Vue) {
    Vue.use(GlobalComponents);
    Vue.use(GlobalDirectives);
    //Vue.use(SideBar);
    Vue.use(Notifications);
    Vue.use(BootstrapVue);
    Vue.use(IconsPlugin);
    Vue.use(VueTheMask)
    Vue.use(Antd)
    configure({
      classes: {
        valid: 'is-valid',
        invalid: 'is-invalid',
        dirty: ['is-dirty', 'is-dirty'], // multiple classes per flag!
      }
    })
  }
  
};
