import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);
import app from './modules/app'
import session from './modules/session'
import account from './modules/account'
import user from './modules/user'
import role from './modules/role'
import tenant from './modules/tenant'
import estadoParticipacion from './modules/OZONE/estadoParticipacion';
import estadoEnvio from './modules/OZONE/estadoEnvio';
import subEstadoParticipacion from './modules/OZONE/subEstadoParticipacion';
import participacionDato from './modules/OZONE/participacionDato';
import campana from './modules/OZONE/campana';
import participacion from './modules/OZONE/participacion';
import campo from './modules/OZONE/campo';
import grupo from './modules/OZONE/grupo';
import franqueo from './modules/OZONE/franqueo';
import ean from './modules/OZONE/ean';
import cliente from './modules/OZONE/cliente';
import marca from './modules/OZONE/marca';
import email from './modules/OZONE/email';
import campanaTipo from './modules/OZONE/campanaTipo';
import estadoCampana from './modules/OZONE/estadoCampana';
import cuentaEmisora from './modules/OZONE/cuentaEmisora';
import terminalBizum from './modules/OZONE/terminalBizum';
import emailEstadoParticipacion from './modules/OZONE/emailEstadoParticipacion';
import reglasTicket from './modules/OZONE/reglasTicket';
import transferencia from './modules/OZONE/transferencia';
import audit from './modules/audit';
import exportacion from './modules/OZONE/exportacion';
import callCenter from './modules/OZONE/callCenter';
import callCenterCampana from './modules/OZONE/callCenterCampana';
import homeModule from './modules/OZONE/home';
import metodoEnvio from './modules/OZONE/metodoEnvio';
import motivoDevolucion from './modules/OZONE/motivoDevolucion';
import envio from './modules/OZONE/envios';
import importacion from './modules/OZONE/importacion';
import finder from './modules/OZONE/finder';
import validateImagenCampana from './modules/OZONE/validateImagenCampana';
import regalo from'./modules/OZONE/regalo';
import servicioMensajeria from './modules/OZONE/servicioMensajeria';
import tienda from './modules/OZONE/tienda';
import provincia from './modules/OZONE/provincia';
import municipio from './modules/OZONE/municipio';
import ficheroEnvio from './modules/OZONE/ficheroEnvio';
import deduplicacion from './modules/OZONE/deduplicacion';
import tipoFormaPagoCashback from './modules/OZONE/tipoPagoCashback';

import charlie from './modules/OZONE/charlie';
const store = new Vuex.Store({
    state: {
        //
    },
    mutations: {
        //
    },
    actions: {

    },
    modules: {
        app,
        session,
        account,
        user,
        role,
        tenant,
        estadoParticipacion,
        estadoEnvio,
        subEstadoParticipacion,
        participacionDato,
        campana,
        participacion,
        campo,
        grupo,
        franqueo,
        cliente,
        marca,
        ean,
        email,
        campanaTipo,
        estadoCampana,
        cuentaEmisora,
        terminalBizum,
        transferencia,
        exportacion,
        emailEstadoParticipacion,
        reglasTicket,
        audit,
        callCenter,
        callCenterCampana,
        homeModule,
        metodoEnvio,
        motivoDevolucion,
        envio,
        importacion,
        validateImagenCampana,
        finder,
        regalo,
        servicioMensajeria,
        tienda,
        provincia,
        municipio,
        ficheroEnvio,
        deduplicacion,
        tipoFormaPagoCashback,
        charlie
    }
});

export default store;