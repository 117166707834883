
import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator'
import AbpBase from '../../lib/abpbase'
import Tab from '../Tabs/Tab.vue'
@Component({
  //components: { Badge, Popover }
})
export default class Notice extends AbpBase {
  noticeSpinShow: boolean = true
  wrapper: any = document.getElementById('notice')
  visible = false

  get noticeList(): Array<any> {
    return this.$store.state.app.noticeList
  }
  get unReadCount() {
    return this.noticeList.filter((n) => n.read == false).length
  }
  get noticeArray() {
    return this.noticeList.filter((n) => n.read == false && n.type === 0)
  }
  get noticeCount() {
    return this.noticeList.filter((n) => n.read == false && n.type === 0).length
  }
  get messageArray() {
    return this.noticeList.filter((n) => n.read == false && n.type === 1)
  }
  get messageCount() {
    return this.noticeList.filter((n) => n.read == false && n.type === 1).length
  }
  get taskArray() {
    return this.noticeList.filter((n) => n.read == false && n.type === 2)
  }
  get taskCount() {
    return this.noticeList.filter((n) => n.read == false && n.type === 2).length
  }
  get noticeLabel() {
    let name = this.L('Notice')
    if (this.noticeCount > 0) {
      return `${name}(${this.noticeCount})`
    } else {
      return name
    }
  }
  get messageLabel() {
    let name = this.L('Message')
    if (this.messageCount > 0) {
      return `${name}(${this.messageCount})`
    } else {
      return name
    }
  }
  get taskLabel() {
    let name = this.L('Task')
    if (this.taskCount > 0) {
      return `${name}(${this.taskCount})`
    } else {
      return name
    }
  }

  get user() {
    return this.$store.state.session.user
  }

  @Watch('user')
  userChange() {
    this.getNotices()
  }

  created() {
    this.wrapper = document.getElementById('layout-dashboard')
  }
  setReaded(notice: any) {
    notice.read = true
    this.$store
      .dispatch({
        type: 'app/setNoticeReaded',
        data: notice
      })
      .then(() => {
        this.getNotices()
      })
  }

  getNotices() {
    this.$store
      .dispatch({
        type: 'app/getNoticeList',
        data: this.$store.state.session.user.id || 0
      })
      .then(() => {
        this.noticeSpinShow = false
      })
    // setInterval(() => {
    //   this.getNotices()
    // }, 20000)
  }
}
