import {Store,Module,ActionContext} from 'vuex'
import ListModule from './list-module'
import ListState from './list-state'
//import Role from '../entities/role'
import Ajax from '../../lib/ajax'
import PageResult from '@/store/entities/page-result';
import PermissionTree from '../entities/permissionTree'
import EntityChange from '../entities/entityChange';
import EntityPropertyChange from '../entities/EntityPropertyChange';

interface AuditState extends ListState<EntityChange>{
    listPropertyChange: Array<EntityPropertyChange>
}
class RoleModule extends ListModule<AuditState,any,EntityChange>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize:10,
        list: new Array<EntityChange>(),
        listPropertyChange: new Array<EntityPropertyChange>(),
        loading:false,
    }
    actions={
        async getAll(context:ActionContext<AuditState,any>,payload:any){
            context.state.loading=true;
            let reponse=await Ajax.get('/api/services/app/AuditLog/GetEntityTypeChanges',{params:payload.data});
            context.state.loading=false;
            let page=reponse.data.result as PageResult<EntityChange>;
            context.state.totalCount=page.totalCount;
            context.state.list = page.items;
        },
        
        async getDetail(context:ActionContext<AuditState,any>,payload:any){
            context.state.loading = true;
            let reponse=await Ajax.get('/api/services/app/AuditLog/GetEntityPropertyChanges',{params:payload.data});
            
            context.state.loading = false;
            context.state.listPropertyChange =reponse.data.result as Array<EntityPropertyChange>;
        },
       
    };
    mutations={
        setCurrentPage(state:AuditState,page:number){
            state.currentPage=page;
        },
        setPageSize(state:AuditState,pagesize:number){
            state.pageSize=pagesize;
        },
    }
}
const roleModule=new RoleModule();
export default roleModule;