import Entity from '../entity'
export default class Email extends Entity<number>{
    defaultFromAddress: string;
    defaultFromDisplayName: string;
    host: string;
    port: string;
    userName: string;
    password: string;
    domain: string;
    enableSsl: boolean;
    useDefaultCredentials: boolean;
    
}